var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", {}, [
      _c("form", { staticClass: "uk-form-stacked" }, [
        _c("div", { staticClass: "uk-margin" }, [
          _c(
            "label",
            {
              staticClass: "uk-form-label",
              attrs: { for: "form-stacked-select" },
            },
            [_vm._v("Select Month")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "uk-form-controls" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedMonth,
                    expression: "selectedMonth",
                  },
                ],
                staticClass: "uk-select",
                attrs: { id: "form-stacked-select" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.selectedMonth = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              _vm._l(_vm.getLastMonths(10), function (month) {
                return _c(
                  "option",
                  {
                    key: month.startDate,
                    domProps: { value: month.startDate },
                  },
                  [_vm._v(_vm._s(month.title))]
                )
              }),
              0
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "uk-grid-divider uk-child-width-expand@s",
          attrs: { "uk-grid": "" },
        },
        [
          _c("div", [
            _c("div", { staticClass: "uk-margin" }, [
              _c(
                "label",
                {
                  staticClass: "uk-form-label",
                  attrs: { for: "form-stacked-text" },
                },
                [_vm._v("Number of Orders")]
              ),
              _vm._v(" "),
              _c("div", [
                _vm._v(_vm._s(_vm.report.count) + " Orders"),
                _c("br"),
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.report.wonOrders.length) +
                    " won Orders / " +
                    _vm._s(_vm.report.lostOrders.length) +
                    " lost Orders"
                ),
                _c("br"),
                _vm._v(
                  "\n                        " +
                    _vm._s(
                      _vm._f("float")(
                        (_vm.report.wonOrders.length / _vm.report.count) * 100,
                        2
                      )
                    ) +
                    " % Success Rate\n                        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "uk-margin" }, [
              _c(
                "label",
                {
                  staticClass: "uk-form-label",
                  attrs: { for: "form-stacked-text" },
                },
                [_vm._v("Total Gross")]
              ),
              _vm._v(" "),
              _c("div", [_vm._v(_vm._s(_vm.report.total2) + " USDT")]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "uk-margin" }, [
              _c(
                "label",
                {
                  staticClass: "uk-form-label",
                  attrs: { for: "form-stacked-text" },
                },
                [_vm._v("Total Fees paid")]
              ),
              _vm._v(" "),
              _c("div", [
                _vm._v(
                  _vm._s(_vm.report.fees) +
                    " BNB / " +
                    _vm._s(_vm.report.feesQuote) +
                    " USDT"
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "uk-margin" }, [
              _c(
                "label",
                {
                  staticClass: "uk-form-label",
                  attrs: { for: "form-stacked-text" },
                },
                [_vm._v("Total Net")]
              ),
              _vm._v(" "),
              _c("div", [_vm._v(_vm._s(_vm.report.totalNet2) + " USDT")]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("div", { staticClass: "uk-margin" }, [
              _c(
                "label",
                {
                  staticClass: "uk-form-label",
                  attrs: { for: "form-stacked-text" },
                },
                [_vm._v("Best Order")]
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _vm._v(_vm._s(_vm.report.bestOrder.totalNet) + " USDT, "),
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: { path: "order/" + _vm.report.bestOrder.id },
                      },
                    },
                    [_vm._v("Go to order")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "uk-margin" }, [
              _c(
                "label",
                {
                  staticClass: "uk-form-label",
                  attrs: { for: "form-stacked-text" },
                },
                [_vm._v("Worst Order")]
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _vm._v(_vm._s(_vm.report.worstOrder.totalNet) + " USDT, "),
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: { path: "order/" + _vm.report.worstOrder.id },
                      },
                    },
                    [_vm._v("Go to order")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "uk-margin" }, [
              _c(
                "label",
                {
                  staticClass: "uk-form-label",
                  attrs: { for: "form-stacked-text" },
                },
                [_vm._v("Longest Order")]
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _vm._v(
                    _vm._s(
                      _vm._f("durationString")(
                        _vm.report.duration.longest.durationS
                      )
                    ) +
                      " / " +
                      _vm._s(_vm.report.duration.longest.durationBars) +
                      " bars , "
                  ),
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: { path: "order/" + _vm.report.duration.longest.id },
                      },
                    },
                    [_vm._v("Go to order")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "uk-margin" }, [
              _c(
                "label",
                {
                  staticClass: "uk-form-label",
                  attrs: { for: "form-stacked-text" },
                },
                [_vm._v("Shortest Order")]
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _vm._v(
                    _vm._s(
                      _vm._f("durationString")(
                        _vm.report.duration.shortest.durationS
                      )
                    ) +
                      " / " +
                      _vm._s(_vm.report.duration.shortest.durationBars) +
                      " bars , "
                  ),
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          path: "order/" + _vm.report.duration.shortest.id,
                        },
                      },
                    },
                    [_vm._v("Go to order")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("h2", [_vm._v("Open orders")]),
      _vm._v(" "),
      _vm.report.openOrders.length > 0
        ? _c(
            "button",
            {
              staticClass: "uk-button uk-button-primary",
              on: { click: _vm.refreshOrders },
            },
            [_vm._v("Resolve open orders")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("table", { staticClass: "uk-table uk-table-divider" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          [
            _vm._l(_vm.report.openOrders, function (order) {
              return _c("tr", { key: order.id }, [
                _c("td", [_vm._v(_vm._s(order.id))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(order.data.created_date))]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "Strategy",
                            params: { strategyId: order.strategy.id },
                          },
                        },
                      },
                      [_vm._v(_vm._s(order.strategy.name))]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("td", [
                  _vm._v(_vm._s(order.data.symbol_from + order.data.symbol_to)),
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    _vm._s(order.data.price) +
                      " " +
                      _vm._s(order.data.symbol_to)
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { path: "order/" + order.id } } },
                      [_vm._v("Open order")]
                    ),
                  ],
                  1
                ),
              ])
            }),
            _vm._v(" "),
            _vm.report.openOrders.length === 0
              ? _c("tr", [
                  _c(
                    "td",
                    { staticClass: "uk-text-center", attrs: { colspan: "5" } },
                    [_vm._v("No open orders")]
                  ),
                ])
              : _vm._e(),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("h2", [_vm._v("Invalid orders")]),
      _vm._v(" "),
      _c("table", { staticClass: "uk-table uk-table-divider" }, [
        _vm._m(1),
        _vm._v(" "),
        _c(
          "tbody",
          [
            _vm._l(_vm.report.invalidOrders, function (order) {
              return _c("tr", { key: order.id }, [
                _c("td", [_vm._v(_vm._s(order.id))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(order.data.created_date))]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "Strategy",
                            params: { strategyId: order.strategy.id },
                          },
                        },
                      },
                      [_vm._v(_vm._s(order.strategy.name))]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("td", [
                  _vm._v(_vm._s(order.data.symbol_from + order.data.symbol_to)),
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    _vm._s(order.data.price) +
                      " " +
                      _vm._s(order.data.symbol_to)
                  ),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "uk-button uk-button-primary",
                      on: {
                        click: function ($event) {
                          return _vm.refreshOrder(order.id)
                        },
                      },
                    },
                    [_vm._v("Update")]
                  ),
                ]),
              ])
            }),
            _vm._v(" "),
            _vm.report.invalidOrders.length === 0
              ? _c("tr", [
                  _c(
                    "td",
                    { staticClass: "uk-text-center", attrs: { colspan: "5" } },
                    [_vm._v("No invalid orders")]
                  ),
                ])
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Id")]),
        _vm._v(" "),
        _c("th", [_vm._v("Order Date")]),
        _vm._v(" "),
        _c("th", [_vm._v("Strategy")]),
        _vm._v(" "),
        _c("th", [_vm._v("Symbol")]),
        _vm._v(" "),
        _c("th", [_vm._v("Entry Price")]),
        _vm._v(" "),
        _c("th", [_vm._v(" ")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Id")]),
        _vm._v(" "),
        _c("th", [_vm._v("Order Date")]),
        _vm._v(" "),
        _c("th", [_vm._v("Strategy")]),
        _vm._v(" "),
        _c("th", [_vm._v("Symbol")]),
        _vm._v(" "),
        _c("th", [_vm._v("Entry Price")]),
        _vm._v(" "),
        _c("th", [_vm._v(" ")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }