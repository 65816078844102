var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", [_vm._v("Orders")]),
    _vm._v(" "),
    _c("table", { staticClass: "uk-table uk-table-divider" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.orders, function (order) {
          return _c("tr", { key: order.id }, [
            _c("td", [_vm._v(_vm._s(order.created_date))]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "Strategy",
                        params: { strategyId: order.strategy_id },
                      },
                    },
                  },
                  [_vm._v(_vm._s(order.strategy_name))]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(order.symbol))]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(order.success))]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c(
                  "router-link",
                  { attrs: { to: { path: "order/" + order.id } } },
                  [_vm._v("Open order")]
                ),
              ],
              1
            ),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Order Date")]),
        _vm._v(" "),
        _c("th", [_vm._v("Strategy")]),
        _vm._v(" "),
        _c("th", [_vm._v("Symbol")]),
        _vm._v(" "),
        _c("th", [_vm._v("Success")]),
        _vm._v(" "),
        _c("th", [_vm._v(" ")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }