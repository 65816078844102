import { Overlay } from 'trading-vue-js'

export default {
    name: 'DrawLine',
    mixins: [Overlay],
    methods: {
        meta_info() {
            return {
                version: '1.0.0', // Required
                author: 'Florian Lenz', // Required
                desc: 'Simple Line', // Recommended
                preset: {}, // Optional
                contact: '<email>',  // Arbitrary
                github: '<GitHub Page>' // Arbitrary
            }
        },
        draw(ctx) {            
            let layout = this.$props.layout // Layout object (see API BOOK)
            ctx.lineWidth = 1
            ctx.strokeStyle = 'white'
            ctx.font = '14px Arial'
            ctx.textAlign = 'right'
            
            for (var i in this.$props.data) {
                
                ctx.beginPath()
                ctx.setLineDash([5, 5]);

                let $startX = layout.t2screen(this.$props.data[i][0])
                let $startY = layout.$2screen(this.$props.data[i][1])
                let $endX = layout.t2screen(this.$props.data[i][2])
                let $endY = layout.$2screen(this.$props.data[i][3])
                
                ctx.beginPath()
                ctx.moveTo($startX, $startY);
                ctx.lineTo($endX, $endY);
                ctx.stroke();

                // Set label
                ctx.fillStyle = 'rgb(255,225,255)';
                ctx.fillText(this.$props.data[i][4], $startX, $endY - 5)
            }
        },
        use_for() {
            return ['DrawLine']
        },
        legend(values) {
            return [
                {
                    value: `Low: ${values[1]}`,
                    color: 'white'
                }, {
                    value: `High: ${values[3]}`,
                    color: 'white'
                }
            ]
        }
    },
}