<template>
	<div>
        <h1>Orders</h1>
        <table class="uk-table uk-table-divider">
            <thead>
                <tr>
                    <th>Strategy Name</th>
                    <th>Status</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="strategy in strategies" :key="strategy.id">
                    <td>{{strategy.name}}</td>
                    <td>{{strategy.status}}</td>
                    <td>
                        <router-link :to="{ path: 'strategy/' + strategy.id }">Open Strategy</router-link>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import Axios from 'axios';

	export default {
		name: 'Strategies',
        components: {
        },
		data () {
			return {
                strategies: []
			}
		},
		mounted: function() {
            Axios
        	.get('/strategies')
        	.then( (response) => {	
        		this.strategies = response.data
        	})
        	.catch(function (error) {
        		console.log(error)
                
        	})
        },
		methods: {
		},
		computed: {
		},
	}
	
</script>

<style scoped lang="scss">
</style>
