<template>
    <div>
        <TradingVue ref="tvjs" :data="chart" :chart-config=" { DEFAULT_LEN: 100 }" :width="this.width" :height="this.height" :overlays="overlays" :toolbar="true" :title-txt="chartTitle"></TradingVue>
        <TradingVue ref="tvjs2" :data="chartDetail" :chart-config=" { DEFAULT_LEN: 100 }" :width="this.width" :height="this.height" :overlays="overlays" :toolbar="true" :title-txt="chartDetailTitle" class="uk-margin-top"></TradingVue>

        <div v-if="status === 'idle'">
            <div class="uk-section">
                <div class="uk-grid-divider uk-child-width-expand@s" uk-grid>
                    <div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Symbol</label>
                            <div>{{order.symbol_from + order.symbol_to}}</div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Timeframe</label>
                            <div>{{order.asset.timeframe}}</div>
                        </div>

                        <div class="uk-margin" uk-grid>
                            <div>
                                <label class="uk-form-label" for="form-stacked-text">Entry date</label>
                                <div>{{order.created_date}}</div>
                            </div>
                            
                            <div>
                                <label class="uk-form-label" for="form-stacked-text">Exit date</label>
                                <div>{{order.LIMIT_MAKER.updated_date}}</div>
                            </div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Duration</label>
                            <div>{{durationComp}}</div>
                        </div>
                    </div>

                    <div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Strategy</label>
                            <div>
                                <router-link :to="{name: 'Strategy', params: {strategyId: order.strategy.id}}" v-slot="{ href, navigate}">
                                    <a :href="href" @click="navigate">{{order.strategy.name}}</a>
                                </router-link>
                            </div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Profit | Loss</label>
                            <div :class="{'uk-text-success': profitComp[0] > 0, 'uk-text-danger': profitComp[0] < 0}">
                                <span v-if="profitComp[0] > 0">+</span><span v-else>-</span>{{profitComp[0] | float(order.asset.info.quoteAssetPrecision)}} {{order.symbol_to}} ({{profitComp[1]}} %)
                            </div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Risk Reward Ratio (wanted | realized)</label>
                            <div>{{order.strategy.options.riskRewardRatio | float(2)}} | {{RRRComp | float}}</div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Invest</label>
                            <div>{{order.cummulative_quote_qty | float(order.asset.info.quoteAssetPrecision)}} {{order.symbol_to}}</div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Lot</label>
                            <div>{{order.exec_qty | float(order.asset.info.baseAssetPrecision)}} {{order.symbol_from}}</div>
                        </div>
                    </div>

                    <div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Total commission paid</label>
                            <div>{{totalCommissionComp | float(order.asset.info.baseCommissionPrecision)}} {{order.commission_asset}}</div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Total commission quote value</label>
                            <div>{{totalCommissionQuoteComp | float(order.asset.info.quoteCommissionPrecision) }} {{order.symbol_to}}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="uk-section">
                <div class="uk-grid-divider uk-child-width-expand@s" uk-grid>
                    <div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Type</label>
                            <div>{{order.type}}</div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Status</label>
                            <div>{{order.status}}</div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Create date</label>
                            <div>{{order.created_date}}</div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Filled date</label>
                            <div>{{order.updated_date}}</div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Price</label>
                            <div>{{order.price | float(order.asset.info.quoteAssetPrecision)}} {{order.symbol_to}}</div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Lot (ordered | realized)</label>
                            <div>{{order.orig_qty | float(order.asset.info.baseAssetPrecision)}} | {{order.exec_qty | float(order.asset.info.baseAssetPrecision)}} {{order.symbol_from}}</div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Invest</label>
                            <div>{{order.price * order.exec_qty | float(order.asset.info.quoteAssetPrecision)}} {{order.symbol_to}}</div>
                        </div>

                        <div v-if="order.status === 'FILLED'" class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Commission paid</label>
                            <div>{{order.commission | float(order.asset.info.baseCommissionPrecision)}} {{order.commission_asset}}</div>
                        </div>

                        <div v-if="order.status === 'FILLED'" class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Commission quote value</label>
                            <div>{{order.commission_quote_value | float(order.asset.info.quoteCommissionPrecision)}} {{order.symbol_to}}</div>
                        </div>
                    </div>

                    <div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Type</label>
                            <div>{{order.LIMIT_MAKER.type}}</div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Status</label>
                            <div>{{order.LIMIT_MAKER.status}}</div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Create date</label>
                            <div>{{order.LIMIT_MAKER.created_date}}</div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Filled date</label>
                            <div>{{order.LIMIT_MAKER.updated_date}}</div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Price</label>
                            <div>{{order.LIMIT_MAKER.price | float(order.asset.info.quoteAssetPrecision)}} {{order.LIMIT_MAKER.symbol_to}}</div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Lot (ordered | realized)</label>
                            <div>{{order.LIMIT_MAKER.orig_qty | float(order.asset.info.baseAssetPrecision)}} | {{order.LIMIT_MAKER.exec_qty | float(order.asset.info.baseAssetPrecision)}} {{order.LIMIT_MAKER.symbol_from}}</div>
                        </div>

                        <div v-if="order.LIMIT_MAKER.status === 'FILLED'">
                            <div class="uk-margin">
                                <label class="uk-form-label" for="form-stacked-text">Return</label>
                                <div>{{order.LIMIT_MAKER.price * order.LIMIT_MAKER.exec_qty | float(order.asset.info.quoteAssetPrecision)}} {{order.LIMIT_MAKER.symbol_to}}</div>
                            </div>

                            <div class="uk-margin">
                                <label class="uk-form-label" for="form-stacked-text">Commission paid</label>
                                <div>{{order.LIMIT_MAKER.commission | float(order.asset.info.baseCommissionPrecision)}} {{order.LIMIT_MAKER.commission_asset}}</div>
                            </div>

                            <div class="uk-margin">
                                <label class="uk-form-label" for="form-stacked-text">Commission quote value</label>
                                <div>{{order.LIMIT_MAKER.commission_quote_value | float(order.asset.info.quoteCommissionPrecision)}} {{order.LIMIT_MAKER.symbol_to}}</div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Type</label>
                            <div>{{order.STOP_LOSS_LIMIT.type}}</div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Status</label>
                            <div>{{order.STOP_LOSS_LIMIT.status}}</div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Create date</label>
                            <div>{{order.STOP_LOSS_LIMIT.created_date}}</div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Filled date</label>
                            <div>{{order.STOP_LOSS_LIMIT.updated_date}}</div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Price</label>
                            <div>{{order.STOP_LOSS_LIMIT.price | float(order.asset.info.quoteAssetPrecision)}} {{order.STOP_LOSS_LIMIT.symbol_to}}</div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Lot (ordered | realized)</label>
                            <div>{{order.STOP_LOSS_LIMIT.orig_qty | float(order.asset.info.baseAssetPrecision)}} | {{order.STOP_LOSS_LIMIT.exec_qty | float(order.asset.info.baseAssetPrecision)}} {{order.STOP_LOSS_LIMIT.symbol_from}}</div>
                        </div>

                        <div v-if="order.STOP_LOSS_LIMIT.status === 'FILLED'">
                            <div class="uk-margin">
                                <label class="uk-form-label" for="form-stacked-text">Return</label>
                                <div>{{order.STOP_LOSS_LIMIT.price * order.STOP_LOSS_LIMIT.exec_qty | float(order.asset.info.quoteAssetPrecision)}} {{order.STOP_LOSS_LIMIT.symbol_to}}</div>
                            </div>

                            <div class="uk-margin">
                                <label class="uk-form-label" for="form-stacked-text">Commission paid</label>
                                <div>{{order.STOP_LOSS_LIMIT.commission | float(order.asset.info.baseCommissionPrecision)}} {{order.STOP_LOSS_LIMIT.commissionAsset}}</div>
                            </div>

                            <div class="uk-margin">
                                <label class="uk-form-label" for="form-stacked-text">Commission quote value</label>
                                <div>{{order.STOP_LOSS_LIMIT.commission_quote_value | float(order.asset.info.quoteCommissionPrecision)}} {{order.STOP_LOSS_LIMIT.symbol_to}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="uk-section">
                <h2>Strategy log</h2>
                <p>Log output of strategy which set the order</p>
                <textarea v-if="order.log.log !== undefined" v-html="order.log.log" disabled></textarea>
            </div>
        </div>
    </div>
</template>

<script>
    import { TradingVue, DataCube } from 'trading-vue-js'
    import Trade from './Trade.js'
    import TradeSimple from './TradeSimple.js'
    import Overlays from 'tvjs-overlays'
    import {mapState} from 'vuex'

	export default {
		name: 'Order',
        components: { TradingVue },
		data () {
			return {
                status: 'loading',
                chartTitle: '',
                chartDetailTitle: '',
                chart: new DataCube({
                    grid: { "logScale": true },
                    ohlcv: [],
                    chart: { tf: '15m', data: []},
                    onchart: [
                        { type: 'BB', name: 'BBL', data: [], settings: { lineWidth: false, color: false, backColor: false, showMid: true }}
                    ],
                    offchart:  [
                        {
                            name: "MACD", type: "MACD", data: [],
                            settings: { fast: 12, slow: 26, smooth: 9, macdColor: 'blue', signalColor: 'orange', histColors: [ "#35a776", "#79e0b3", "#e54150", "#ea969e"]}
                        },
                        {
                            type: 'RSI', name: 'RSI', data: [],
                            settings: {}
                        }
                    ]
                }),
                chartDetail: new DataCube({
                    grid: { "logScale": true },
                    ohlcv: [],
                    chart: { tf: '1m', data: []},
                    onchart: [
                        { type: 'BB', name: 'BBL', data: [], settings: { lineWidth: false, color: false, backColor: false, showMid: true }}
                    ],
                    offchart:  [
                        {
                            name: "MACD", type: "MACD", data: [],
                            settings: { fast: 12, slow: 26, smooth: 9, macdColor: 'blue', signalColor: 'orange', histColors: [ "#35a776", "#79e0b3", "#e54150", "#ea969e"]}
                        },
                        {
                            type: 'RSI', name: 'RSI', data: [],
                            settings: {}
                        }
                    ]
                }),
                width: window.innerWidth,
                height: window.innerHeight,
                overlays: [Trade, TradeSimple, Overlays['MACD'], Overlays['BB'], Overlays['EMA'], Overlays['RSI']]
            }
		},
		mounted: function() {
            this.init();
        },
		methods: {
			onResize(event) {                
                this.width = document.getElementById('viewport').clientWidth
                this.height = window.innerHeight - 15
            },
            init() {
                this.$store.dispatch('loadOrder', {
                    orderId: this.$route.params.orderId
                }).then(() => {
                    this.status = 'idle'
                    
                    window.addEventListener('resize', this.onResize)
                    this.chartTitle = this.order.asset.symbol_from + this.order.asset.symbol_to + ' ' + this.order.strategy.timeframe
                    this.chartDetailTitle = this.order.asset.symbol_from + this.order.asset.symbol_to + ' 1m'

                    // Set up original timeframe chart
                    var ema52 = [],
                        ema104 = [],
                        ema208 = [],
                        vci = [];

                    var ohlcv = []
                    this.order.ohlc.forEach((bar) => {
                        var row = new Array( parseInt(bar.unix_open * 1000), parseFloat(bar.open), parseFloat(bar.high), parseFloat(bar.low), parseFloat(bar.close), parseFloat(bar.asset_volume))
                        
                        ohlcv.push(row)
                        // ema52.push([parseInt(bar.unix_open * 1000), bar.ema52])
                        // ema104.push([parseInt(bar.unix_open * 1000), bar.ema104])
                        // ema208.push([parseInt(bar.unix_open * 1000), bar.ema208])
                        
                        // var vcirow = new Array( parseInt(bar.unix_open * 1000), parseFloat(bar.vci.open), parseFloat(bar.vci.high), parseFloat(bar.vci.low), parseFloat(bar.vci.close))
                        
                        // vci.push(vcirow)
                    })
                    
                    this.chart.set('chart.data', ohlcv)

                    this.chart.add('onchart', {
                        name: "Trade",
                        type: "TradeSimple",
                        data: [
                            [
                                parseInt(this.order.created_unix),               // timestamp on order create
                                parseInt(this.order.LIMIT_MAKER.updated_unix),   // timestamp on order exit
                                parseFloat(this.order.price),                    // buy lvl 
                                parseFloat(this.order.LIMIT_MAKER.price),        // target profit lvl
                                parseFloat(this.order.STOP_LOSS_LIMIT.price),    // stop loss lvl
                                this.order.created_date,
                                this.order.LIMIT_MAKER.updated_date
                            ]
                        ],
                        "settings": {
                            "z-index": 1
                        }
                    })

                    // this.chart.add('onchart', { "name": "EMA52", "type": "Spline", "data": ema52, "settings": { color: 'green' }})
                    // this.chart.add('onchart', { "name": "EMA104", "type": "Spline", "data": ema104, "settings": { color: 'orange' }})
                    // this.chart.add('onchart', { "name": "EMA208", "type": "Spline", "data": ema208, "settings": { color: 'red' }})

                    // this.chart.add('offchart', { "name": "VCI", "type": "Candles", "data": vci, settings: {priceLine: false, showVolume: false, colorCandleUp: 'yellow'}})

                    // Set up detail timeframe chart
                    var ema52 = [],
                        ema104 = [],
                        ema208 = [];

                    var ohlcv = []
                    this.order.ohlcDetail.forEach((bar) => {
                        var row = new Array( parseInt(bar.unix_open * 1000), parseFloat(bar.open), parseFloat(bar.high), parseFloat(bar.low), parseFloat(bar.close), parseFloat(bar.asset_volume))
                        
                        ohlcv.push(row)
                        // ema52.push([parseInt(bar.unix_open * 1000), bar.ema52])
                        // ema104.push([parseInt(bar.unix_open * 1000), bar.ema104])
                        // ema208.push([parseInt(bar.unix_open * 1000), bar.ema208])
                    })
                    
                    this.chartDetail.set('chart.data', ohlcv)
                    this.chartDetail.add('onchart', {
                        name: "TradeSimple",
                        type: "Trades",
                        data: [
                            [
                                parseInt(this.order.created_unix),               // timestamp on order create
                                parseInt(this.order.LIMIT_MAKER.updated_unix),   // timestamp on order exit
                                parseFloat(this.order.price),                    // buy lvl 
                                parseFloat(this.order.LIMIT_MAKER.price),        // target profit lvl
                                parseFloat(this.order.STOP_LOSS_LIMIT.price),    // stop loss lvl
                                this.order.created_date,
                                this.order.LIMIT_MAKER.updated_date
                            ]
                        ],
                        "settings": {
                            "z-index": 1
                        }
                    })

                    this.onResize()
                    window.dc = this.chart
                    window.tv = this.$refs.tvjs

                    window.dc2 = this.chartDetail
                    window.tv2 = this.$refs.tvjs2
                }) 
            }
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize)
		},
		computed: {
            ...mapState({
                order: state => state.order
            }),
            profitComp() {
                let invest = this.order.price * this.order.exec_qty
                let roi = 0.0
                let profit = 0.0
                let loss = 0.0
                if (this.order.LIMIT_MAKER.status === 'FILLED') {
                    roi = this.order.LIMIT_MAKER.price * this.order.LIMIT_MAKER.exec_qty
                    profit = 100 * (1 - (this.order.price / this.order.LIMIT_MAKER.price))

                } else if (this.order.STOP_LOSS_LIMIT.status === 'FILLED') {
                    roi = this.order.STOP_LOSS_LIMIT.price * this.order.STOP_LOSS_LIMIT.exec_qty
                    profit = 100 * (1 - (this.order.price / this.order.STOP_LOSS_LIMIT.price))
                }

                return [(roi - invest).toFixed(2), profit.toFixed(2)]
            },
            durationComp() {
                let seconds = Number(this.order.LIMIT_MAKER.updated_unix / 1000 - this.order.created_unix / 1000)

                var h = Math.floor(seconds / 3600);
                var m = Math.floor(seconds % 3600 / 60);
                var s = Math.floor(seconds % 3600 % 60);

                var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
                var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
                var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
                return hDisplay + mDisplay + sDisplay;

            },
            totalCommissionComp() {
                if (this.order.success)
                    return parseFloat(this.order.commission) + parseFloat(this.order.LIMIT_MAKER.commission)                    
                else
                    return parseFloat(this.order.commission) + parseFloat(this.order.STOP_LOSS_LIMIT.commission)
            },
            totalCommissionQuoteComp() {
                if (this.order.success)
                    return parseFloat(this.order.commission_quote_value) + parseFloat(this.order.LIMIT_MAKER.commission_quote_value)                    
                else
                    return parseFloat(this.order.commission_quote_value) + parseFloat(this.order.STOP_LOSS_LIMIT.commission_quote_value)
            },
            RRRComp() {
                return (this.order.LIMIT_MAKER.price - this.order.price) / (this.order.price - this.order.STOP_LOSS_LIMIT.price)
            }
        },
        watch: {
            '$route.params.orderId': function (id) {                
                this.$refs.tvjs.resetChart()
                this.chart.del('Trades')
                this.status = 'loading'
                this.init()
            }
        },
	}
	
</script>

<style scoped lang="scss">

  @import 'Order';
</style>
