<template>
	<div>
    <h1>Simulated Orders</h1>

    <form  v-on:submit.prevent="loadOrders">
      <table class="uk-table uk-table-divider">
        <thead>
          <tr>
            <th>Order Date</th>
            <th>Strategy</th>
            <th>Symbol</th>
            <th>Success</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr>               
            <td>&nbsp;</td>
            <td>
              <select class="uk-select uk-form-small" v-model="filter.strategy">
                <option :value="''">All Strategies</option>
                <option v-for="strategy in strategies" :key="strategy.id" :value="strategy.id">{{strategy.name}}</option>
              </select>
            </td>
            <td>
              <select class="uk-select uk-form-small" v-model="filter.pair">
                <option :value="''">All Pairs</option>
                <option v-for="pair in assetsGrouped" :key="pair.id" :value="pair.symbol_from + pair.symbol_to">{{pair.symbol_from + pair.symbol_to}}</option>
              </select>
            </td>
            <td>
              <select class="uk-select uk-form-small" v-model="filter.success">
                <option :value="''">All Results</option>
                <option :value="'true'">true</option>
                <option :value="'false'">false</option>
                <option :value="'NULL'">Order is open</option>
              </select>
            </td>
            <td>&nbsp;</td>
          </tr>

          <tr v-for="order in orders" :key="order.id">
            <td>{{order.created_date}}</td>
            <td><router-link :to="{ name: 'Strategy', params: {strategyId: order.strategy.data.id }}">{{order.strategy.data.name}}</router-link></td>
            <td>{{order.symbol}} {{order.asset.timeframe}}</td>
            <td>{{order.success}}</td>
            <td>
              <router-link :to="{ path: 'ordersimulated/' + order.id }" target="_blank">Open order</router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
</template>

<script>
  import Axios from 'axios'
  import { mapState } from 'vuex'

	export default {
		name: 'OrdersSimulated',
        components: {
        },
		data () {
			return {
        orders: [],
        status: 'idle',
        filter: {
          strategy: '',
          pair: '',
          success: ''
        },
			}
		},
		mounted: function() {
      this.loadOrders()
    },
		methods: {
      loadOrders: function() {
        this.status = 'loading'
        this.orders = []

        const params = new URLSearchParams(this.filter);

        Axios
        .get('/orderssimulated', { params })
        .then( (response) => {	
          this.orders = response.data
          this.status = 'idle'
        })
        .catch(function (error) {
          console.log(error)
          this.status = 'idle'
        })
      },
    },
		computed: {
      ...mapState({
        assetsGrouped: state => state.assetsGrouped,
      }),
      ...mapState({
        strategies: state => state.strategies,
      }),
    },
    watch: {
      filter: {
        handler(newValue, oldValue) {
          this.loadOrders()
        },
        deep: true
      }
    }
	}
</script>

<style scoped lang="scss">
  @import 'Orders';
</style>
