import Dashboard from './views/Dashboard';
import Orders from './views/Orders';
import Order from './views/Order';
import OrderSimulated from './views/OrderSimulated';
import OrdersSimulated from './views/OrdersSimulated';
import Strategies from './views/Strategies';
import Strategy from './views/Strategy';

export default [
	
	{
		path: '/',
		component: Dashboard,
		name: 'Dashboard'
	},
	{
		path: '/orders',
		component: Orders,
		name: 'Orders'
	},
	{
		path: '/order/:orderId',
		component: Order,
		name: 'Order'
	},
	{
		path: '/orderssimulated',
		component: OrdersSimulated,
		name: 'OrdersSimulated'
	},
	{
		path: '/ordersimulated/:orderId',
		component: OrderSimulated,
		name: 'OrderSimulated'
	},
	{
		path: '/strategies',
		component: Strategies,
		name: 'Strategies'
	},
	{
		path: '/strategy/:strategyId',
		component: Strategy,
		name: 'Strategy'
	},
	{
		path: "/:catchAll(.*)",
		redirect: '/'
	}
];
