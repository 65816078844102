<template>
    <div>
        <div class="">
            <form class="uk-form-stacked">

                <div class="uk-margin">
                    <label class="uk-form-label" for="form-stacked-select">Select Month</label>
                    <div class="uk-form-controls">
                        <select class="uk-select" id="form-stacked-select" v-model="selectedMonth">
                            <option v-for="month in getLastMonths(10)" :key="month.startDate" :value="month.startDate">{{month.title}}</option>
                        </select>
                    </div>
                </div>
            </form>

            <div class="uk-grid-divider uk-child-width-expand@s" uk-grid>
                <div>
                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-text">Number of Orders</label>
                        <div>{{report.count}} Orders<br />
                            {{report.wonOrders.length}} won Orders / {{report.lostOrders.length}} lost Orders<br />
                            {{(report.wonOrders.length / report.count) * 100 | float(2)}} % Success Rate
                            </div>
                    </div>

                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-text">Total Gross</label>
                        <div>{{report.total2}} USDT</div>
                    </div>

                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-text">Total Fees paid</label>
                        <div>{{report.fees}} BNB / {{report.feesQuote}} USDT</div>
                    </div>

                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-text">Total Net</label>
                        <div>{{report.totalNet2}} USDT</div>
                    </div>
                </div>
            
                <div>
                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-text">Best Order</label>
                        <div>{{report.bestOrder.totalNet}} USDT, <router-link :to="{ path: 'order/' + report.bestOrder.id }">Go to order</router-link></div>
                    </div>

                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-text">Worst Order</label>
                        <div>{{report.worstOrder.totalNet}} USDT, <router-link :to="{ path: 'order/' + report.worstOrder.id }">Go to order</router-link></div>
                    </div>

                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-text">Longest Order</label>
                        <div>{{report.duration.longest.durationS | durationString}} / {{report.duration.longest.durationBars}} bars , <router-link :to="{ path: 'order/' + report.duration.longest.id }">Go to order</router-link></div>
                    </div>

                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-text">Shortest Order</label>
                        <div>{{report.duration.shortest.durationS | durationString}} / {{report.duration.shortest.durationBars}} bars , <router-link :to="{ path: 'order/' + report.duration.shortest.id }">Go to order</router-link></div>
                    </div>
                </div>
            </div>

            <h2>Open orders</h2>
            <button v-on:click="refreshOrders" class="uk-button uk-button-primary" v-if="report.openOrders.length > 0">Resolve open orders</button>
            <table class="uk-table uk-table-divider">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Order Date</th>
                        <th>Strategy</th>
                        <th>Symbol</th>
                        <th>Entry Price</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="order in report.openOrders" :key="order.id">
                        <td>{{order.id}}</td>
                        <td>{{order.data.created_date}}</td>
                        <td><router-link :to="{ name: 'Strategy', params: {strategyId: order.strategy.id }}">{{order.strategy.name}}</router-link></td>
                        <td>{{order.data.symbol_from + order.data.symbol_to}}</td>
                        <td>{{order.data.price}} {{order.data.symbol_to}}</td>
                        <td>
                            <router-link :to="{ path: 'order/' + order.id }">Open order</router-link>
                        </td>
                    </tr>
                    <tr v-if="report.openOrders.length === 0">
                        <td colspan="5" class="uk-text-center">No open orders</td>
                    </tr>
                </tbody>
            </table>

            <h2>Invalid orders</h2>
            <table class="uk-table uk-table-divider">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Order Date</th>
                        <th>Strategy</th>
                        <th>Symbol</th>
                        <th>Entry Price</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="order in report.invalidOrders" :key="order.id">
                        <td>{{order.id}}</td>
                        <td>{{order.data.created_date}}</td>
                        <td><router-link :to="{ name: 'Strategy', params: {strategyId: order.strategy.id }}">{{order.strategy.name}}</router-link></td>
                        <td>{{order.data.symbol_from + order.data.symbol_to}}</td>
                        <td>{{order.data.price}} {{order.data.symbol_to}}</td>
                        <td>
                            <button v-on:click="refreshOrder(order.id)" class="uk-button uk-button-primary">Update</button>
                        </td>
                    </tr>
                    <tr v-if="report.invalidOrders.length === 0">
                        <td colspan="5" class="uk-text-center">No invalid orders</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import Axios from 'axios';

export default {
    name: 'Dashboard',
    data() {
        return {
            selectedMonth: '',
            report: {
                month: {},
                wonOrders: [],
                lostOrders: [],
                invalidOrders: [],
                count: 0,
                bestOrder: {},
                worstOrder: {},
                duration: {
                    longest: {},
                    shortest: {}
                },
                openOrders: []
            },
            status: 'loading'
        }
    },
    methods: {
        refreshOrder(id) {
            Axios
            .patch('orders/refresh?id=' + id)
            .then( (response) => {
                this.getReport()
            })
            .catch((error) => {
                console.log(error)
                
                this.status = 'error'
            })
        },
        refreshOrders() {
            Axios
            .patch('orders/refresh')
            .then( (response) => {
                this.getReport()
            })
                
                // this.$store.dispatch('getReport', this.selectedMonth).then((report) => {
                //     this.report = report
                //     console.log(report)
                    
                //     this.status = 'idle'
                // })
        },
        getReport() {
            Axios
    		.get('report?m=' + this.selectedMonth)
    		.then( (response) => {
    			console.log('report successfully loaded')
    			this.report = response.data
    		})
    		.catch((error) => {
    			console.log(error)
                this.status = 'error'
    		})
        },
        getLastMonths(n) {
            var monthNames = [ "January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December" ];

            var months = new Array();

            var today = new Date();
            var year = today.getFullYear();
            var month = today.getMonth();
            var monthNum = month + 1;

            var i = 0;
            do {
                // months.push(year + (month > 9 ? "" : "0") + month);
                monthNum = month + 1;
                months.push({
                    'title': monthNames[month] + ' ' + year,
                    'startDate': '01.' + (monthNum > 9 ? "" : "0") + monthNum + '.' + year
                })
                
                if(month == 0) {
                    month = 11;
                    year--;
                } else {
                    month--;
                }
                i++;
            } while(i < n);
        
            return months;

        }
    },
    mounted() {
        this.selectedMonth = this.getLastMonths(10)[0].startDate
    },
    computed: {
        monthNameComp() {
            if (typeof this.report.month !== 'undefined') {
                const monthNames = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];
                var date = new Date(this.report.month.startTime);
                var month = date.getMonth();
                return monthNames[month] + ' ' + date.getFullYear();
            } else {
                return false
            }
        },
    },
    watch: {
        selectedMonth: function (newValue, oldValue) {
            this.status = 'loading'
            this.getReport()
        }
    },
}
</script>