/**
* Actions to manipulte vuex store
*/

import Vue from 'vue';
import Vuex from 'vuex';
import Axios from 'axios';
import { reject, resolve } from 'core-js/fn/promise';

const setLoad = ({dispatch, commit, state}, params) => {
	commit('SET_LOAD', params.msg)
}

const unsetLoad = ({dispatch, commit, state}, params) => {
	commit('SET_UNLOAD')
}

const initializeApp = ({dispatch, commit, state}, params) => {

	if (params.showLoad !== false) {
		commit('SET_APP_STATUS', 'loading')
	}

	// get oc metrics
	var bot = new Promise((resolve, reject) => {
		Axios
		.get('assets')
		.then( (response) => {
			commit('SET_ASSETS', response.data)
			commit('SET_APP_STATUS', 'idle');
			resolve()
		})
		.catch((error) => {
			commit('SET_APP_STATUS', 'error');
			reject();
		})
	})

	var strategies = new Promise((resolve, reject) => {
		Axios
		.get('assets')
		.then( (response) => {
			commit('SET_ASSETS', response.data)
			commit('SET_APP_STATUS', 'idle');
			resolve()
		})
		.catch((error) => {
			commit('SET_APP_STATUS', 'error');
			reject();
		})

		Axios
		.get('/strategies')
		.then( (response) => {	
			commit('SET_STRATEGIES', response.data)
			commit('SET_APP_STATUS', 'idle');
      resolve()
		})
		.catch(function (error) {
			commit('SET_APP_STATUS', 'error');
			reject();
		})
	})


	// var contract = new Promise((resolve, reject) => {
	// 	// retrieve contract data from billwerk api. ContractID is set in backend
	// 	Axios
	// 	.post('', {
	// 			'Event': 'Api',
	// 			'Action': 'getFullCustomer'
	// 		})
	// 	.then( (response) => {
	// 		// Set language to oc setting
	// 		response.data.customer.data.Language = Vue.config.language

	// 		if (response.data.contract.data.CurrentPhase.Type === 'Inactive') {
	// 			commit('SET_KEY', {key: 'errorMessage', value: i18n.t('errorContractInactive')})
	// 			commit('SET_KEY', {key: 'showRetry', value: false})
	// 			commit('SET_KEY', {key: 'failed', value: true})
	// 		} else {
	// 			if (typeof response.data.contract.plan.Name.en === 'undefined')
	// 				response.data.contract.plan.Name['en'] = response.data.contract.plan.Name._c
			
	// 			if (typeof response.data.contract.plan.PlanDescription.en === 'undefined')
	// 				response.data.contract.plan.PlanDescription['en'] = response.data.contract.plan.PlanDescription._c
	// 		}

	// 		commit('UPDATE_CUSTOMER', response.data.customer);
	// 		commit('UPDATE_CONTRACT', response.data.contract);

	// 		resolve()
	// 	})
	// 	.catch(function (error) {
	// 		commit('SET_KEY', {key: 'failed', value: true})
	// 		reject()
	// 	})
	// })

	// return Promise.allSettled([metrics, contract]).then(() => {
	// 	commit('SET_INITDONE')
	// 	commit('SET_UNLOAD')
	// })
}

const saveStrategy = ({dispatch, commit, state}, strategy) => {
	return new Promise((resolve, reject) => {
    commit('SET_APP_STATUS', 'loading');
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
		  
		Axios
		.patch('strategy', strategy, config)
		.then( (response) => {
			commit('SET_APP_STATUS', 'idle');
			resolve()
		})
		.catch((error) => {
			commit('SET_APP_STATUS', 'error');
			reject();
		})
	})	
}

const loadOrder = ({dispatch, commit, state}, params) => {
	return new Promise((resolve, reject) => {
		commit('SET_APP_STATUS', 'loading');

		Axios
		.get('order?id=' + params.orderId)
		.then( (response) => {
			commit('SET_ORDER', response.data)
			commit('SET_APP_STATUS', 'idle');
			resolve()
		})
		.catch((error) => {
			commit('SET_APP_STATUS', 'error');
			reject();
		})
	})	
}


export default {
	setLoad,
	unsetLoad,
	initializeApp,
	saveStrategy,
	loadOrder
}
