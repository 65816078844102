var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", [_vm._v("Simulated Orders")]),
    _vm._v(" "),
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.loadOrders.apply(null, arguments)
          },
        },
      },
      [
        _c("table", { staticClass: "uk-table uk-table-divider" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "tbody",
            [
              _c("tr", [
                _c("td", [_vm._v(" ")]),
                _vm._v(" "),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filter.strategy,
                          expression: "filter.strategy",
                        },
                      ],
                      staticClass: "uk-select uk-form-small",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.filter,
                            "strategy",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { domProps: { value: "" } }, [
                        _vm._v("All Strategies"),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.strategies, function (strategy) {
                        return _c(
                          "option",
                          {
                            key: strategy.id,
                            domProps: { value: strategy.id },
                          },
                          [_vm._v(_vm._s(strategy.name))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filter.pair,
                          expression: "filter.pair",
                        },
                      ],
                      staticClass: "uk-select uk-form-small",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.filter,
                            "pair",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { domProps: { value: "" } }, [
                        _vm._v("All Pairs"),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.assetsGrouped, function (pair) {
                        return _c(
                          "option",
                          {
                            key: pair.id,
                            domProps: {
                              value: pair.symbol_from + pair.symbol_to,
                            },
                          },
                          [_vm._v(_vm._s(pair.symbol_from + pair.symbol_to))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filter.success,
                          expression: "filter.success",
                        },
                      ],
                      staticClass: "uk-select uk-form-small",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.filter,
                            "success",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { domProps: { value: "" } }, [
                        _vm._v("All Results"),
                      ]),
                      _vm._v(" "),
                      _c("option", { domProps: { value: "true" } }, [
                        _vm._v("true"),
                      ]),
                      _vm._v(" "),
                      _c("option", { domProps: { value: "false" } }, [
                        _vm._v("false"),
                      ]),
                      _vm._v(" "),
                      _c("option", { domProps: { value: "NULL" } }, [
                        _vm._v("Order is open"),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("td", [_vm._v(" ")]),
              ]),
              _vm._v(" "),
              _vm._l(_vm.orders, function (order) {
                return _c("tr", { key: order.id }, [
                  _c("td", [_vm._v(_vm._s(order.created_date))]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "Strategy",
                              params: { strategyId: order.strategy.data.id },
                            },
                          },
                        },
                        [_vm._v(_vm._s(order.strategy.data.name))]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(order.symbol) + " " + _vm._s(order.asset.timeframe)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(order.success))]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: { path: "ordersimulated/" + order.id },
                            target: "_blank",
                          },
                        },
                        [_vm._v("Open order")]
                      ),
                    ],
                    1
                  ),
                ])
              }),
            ],
            2
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Order Date")]),
        _vm._v(" "),
        _c("th", [_vm._v("Strategy")]),
        _vm._v(" "),
        _c("th", [_vm._v("Symbol")]),
        _vm._v(" "),
        _c("th", [_vm._v("Success")]),
        _vm._v(" "),
        _c("th", [_vm._v(" ")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }