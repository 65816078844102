var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("TradingVue", {
        ref: "tvjs",
        attrs: {
          data: _vm.chart,
          "chart-config": { DEFAULT_LEN: 100 },
          width: this.width,
          height: this.height,
          overlays: _vm.overlays,
          toolbar: true,
          "title-txt": _vm.chartTitle,
        },
      }),
      _vm._v(" "),
      _c("TradingVue", {
        ref: "tvjs2",
        staticClass: "uk-margin-top",
        attrs: {
          data: _vm.chartDetail,
          "chart-config": { DEFAULT_LEN: 100 },
          width: this.width,
          height: this.height,
          overlays: _vm.overlays,
          toolbar: true,
          "title-txt": _vm.chartDetailTitle,
        },
      }),
      _vm._v(" "),
      _vm.status === "idle"
        ? _c("div", [
            _c("div", { staticClass: "uk-section" }, [
              _c(
                "div",
                {
                  staticClass: "uk-grid-divider uk-child-width-expand@s",
                  attrs: { "uk-grid": "" },
                },
                [
                  _c("div", [
                    _c("div", { staticClass: "uk-margin" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "form-stacked-text" },
                        },
                        [_vm._v("Symbol")]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.order.symbol_from + _vm.order.symbol_to)
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-margin" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "form-stacked-text" },
                        },
                        [_vm._v("Timeframe")]
                      ),
                      _vm._v(" "),
                      _c("div", [_vm._v(_vm._s(_vm.order.asset.timeframe))]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "uk-margin", attrs: { "uk-grid": "" } },
                      [
                        _c("div", [
                          _c(
                            "label",
                            {
                              staticClass: "uk-form-label",
                              attrs: { for: "form-stacked-text" },
                            },
                            [_vm._v("Entry date")]
                          ),
                          _vm._v(" "),
                          _c("div", [_vm._v(_vm._s(_vm.order.created_date))]),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "label",
                            {
                              staticClass: "uk-form-label",
                              attrs: { for: "form-stacked-text" },
                            },
                            [_vm._v("Exit date")]
                          ),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(_vm._s(_vm.order.LIMIT_MAKER.updated_date)),
                          ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-margin" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "form-stacked-text" },
                        },
                        [_vm._v("Duration")]
                      ),
                      _vm._v(" "),
                      _c("div", [_vm._v(_vm._s(_vm.durationComp))]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("div", { staticClass: "uk-margin" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "form-stacked-text" },
                        },
                        [_vm._v("Strategy")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("router-link", {
                            attrs: {
                              to: {
                                name: "Strategy",
                                params: { strategyId: _vm.order.strategy.id },
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var href = ref.href
                                    var navigate = ref.navigate
                                    return [
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.order.strategy.name)
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              930545161
                            ),
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-margin" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "form-stacked-text" },
                        },
                        [_vm._v("Profit | Loss")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          class: {
                            "uk-text-success": _vm.profitComp[0] > 0,
                            "uk-text-danger": _vm.profitComp[0] < 0,
                          },
                        },
                        [
                          _vm.profitComp[0] > 0
                            ? _c("span", [_vm._v("+")])
                            : _c("span", [_vm._v("-")]),
                          _vm._v(
                            _vm._s(
                              _vm._f("float")(
                                _vm.profitComp[0],
                                _vm.order.asset.info.quoteAssetPrecision
                              )
                            ) +
                              " " +
                              _vm._s(_vm.order.symbol_to) +
                              " (" +
                              _vm._s(_vm.profitComp[1]) +
                              " %)\n                        "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-margin" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "form-stacked-text" },
                        },
                        [_vm._v("Risk Reward Ratio (wanted | realized)")]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm._f("float")(
                              _vm.order.strategy.options.riskRewardRatio,
                              2
                            )
                          ) +
                            " | " +
                            _vm._s(_vm._f("float")(_vm.RRRComp))
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-margin" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "form-stacked-text" },
                        },
                        [_vm._v("Invest")]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm._f("float")(
                              _vm.order.cummulative_quote_qty,
                              _vm.order.asset.info.quoteAssetPrecision
                            )
                          ) +
                            " " +
                            _vm._s(_vm.order.symbol_to)
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-margin" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "form-stacked-text" },
                        },
                        [_vm._v("Lot")]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm._f("float")(
                              _vm.order.exec_qty,
                              _vm.order.asset.info.baseAssetPrecision
                            )
                          ) +
                            " " +
                            _vm._s(_vm.order.symbol_from)
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("div", { staticClass: "uk-margin" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "form-stacked-text" },
                        },
                        [_vm._v("Total commission paid")]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm._f("float")(
                              _vm.totalCommissionComp,
                              _vm.order.asset.info.baseCommissionPrecision
                            )
                          ) +
                            " " +
                            _vm._s(_vm.order.commission_asset)
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-margin" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "form-stacked-text" },
                        },
                        [_vm._v("Total commission quote value")]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm._f("float")(
                              _vm.totalCommissionQuoteComp,
                              _vm.order.asset.info.quoteCommissionPrecision
                            )
                          ) +
                            " " +
                            _vm._s(_vm.order.symbol_to)
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "uk-section" }, [
              _c(
                "div",
                {
                  staticClass: "uk-grid-divider uk-child-width-expand@s",
                  attrs: { "uk-grid": "" },
                },
                [
                  _c("div", [
                    _c("div", { staticClass: "uk-margin" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "form-stacked-text" },
                        },
                        [_vm._v("Type")]
                      ),
                      _vm._v(" "),
                      _c("div", [_vm._v(_vm._s(_vm.order.type))]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-margin" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "form-stacked-text" },
                        },
                        [_vm._v("Status")]
                      ),
                      _vm._v(" "),
                      _c("div", [_vm._v(_vm._s(_vm.order.status))]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-margin" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "form-stacked-text" },
                        },
                        [_vm._v("Create date")]
                      ),
                      _vm._v(" "),
                      _c("div", [_vm._v(_vm._s(_vm.order.created_date))]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-margin" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "form-stacked-text" },
                        },
                        [_vm._v("Filled date")]
                      ),
                      _vm._v(" "),
                      _c("div", [_vm._v(_vm._s(_vm.order.updated_date))]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-margin" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "form-stacked-text" },
                        },
                        [_vm._v("Price")]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm._f("float")(
                              _vm.order.price,
                              _vm.order.asset.info.quoteAssetPrecision
                            )
                          ) +
                            " " +
                            _vm._s(_vm.order.symbol_to)
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-margin" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "form-stacked-text" },
                        },
                        [_vm._v("Lot (ordered | realized)")]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm._f("float")(
                              _vm.order.orig_qty,
                              _vm.order.asset.info.baseAssetPrecision
                            )
                          ) +
                            " | " +
                            _vm._s(
                              _vm._f("float")(
                                _vm.order.exec_qty,
                                _vm.order.asset.info.baseAssetPrecision
                              )
                            ) +
                            " " +
                            _vm._s(_vm.order.symbol_from)
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-margin" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "form-stacked-text" },
                        },
                        [_vm._v("Invest")]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm._f("float")(
                              _vm.order.price * _vm.order.exec_qty,
                              _vm.order.asset.info.quoteAssetPrecision
                            )
                          ) +
                            " " +
                            _vm._s(_vm.order.symbol_to)
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.order.status === "FILLED"
                      ? _c("div", { staticClass: "uk-margin" }, [
                          _c(
                            "label",
                            {
                              staticClass: "uk-form-label",
                              attrs: { for: "form-stacked-text" },
                            },
                            [_vm._v("Commission paid")]
                          ),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm._f("float")(
                                  _vm.order.commission,
                                  _vm.order.asset.info.baseCommissionPrecision
                                )
                              ) +
                                " " +
                                _vm._s(_vm.order.commission_asset)
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.order.status === "FILLED"
                      ? _c("div", { staticClass: "uk-margin" }, [
                          _c(
                            "label",
                            {
                              staticClass: "uk-form-label",
                              attrs: { for: "form-stacked-text" },
                            },
                            [_vm._v("Commission quote value")]
                          ),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm._f("float")(
                                  _vm.order.commission_quote_value,
                                  _vm.order.asset.info.quoteCommissionPrecision
                                )
                              ) +
                                " " +
                                _vm._s(_vm.order.symbol_to)
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("div", { staticClass: "uk-margin" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "form-stacked-text" },
                        },
                        [_vm._v("Type")]
                      ),
                      _vm._v(" "),
                      _c("div", [_vm._v(_vm._s(_vm.order.LIMIT_MAKER.type))]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-margin" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "form-stacked-text" },
                        },
                        [_vm._v("Status")]
                      ),
                      _vm._v(" "),
                      _c("div", [_vm._v(_vm._s(_vm.order.LIMIT_MAKER.status))]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-margin" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "form-stacked-text" },
                        },
                        [_vm._v("Create date")]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(_vm._s(_vm.order.LIMIT_MAKER.created_date)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-margin" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "form-stacked-text" },
                        },
                        [_vm._v("Filled date")]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(_vm._s(_vm.order.LIMIT_MAKER.updated_date)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-margin" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "form-stacked-text" },
                        },
                        [_vm._v("Price")]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm._f("float")(
                              _vm.order.LIMIT_MAKER.price,
                              _vm.order.asset.info.quoteAssetPrecision
                            )
                          ) +
                            " " +
                            _vm._s(_vm.order.LIMIT_MAKER.symbol_to)
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-margin" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "form-stacked-text" },
                        },
                        [_vm._v("Lot (ordered | realized)")]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm._f("float")(
                              _vm.order.LIMIT_MAKER.orig_qty,
                              _vm.order.asset.info.baseAssetPrecision
                            )
                          ) +
                            " | " +
                            _vm._s(
                              _vm._f("float")(
                                _vm.order.LIMIT_MAKER.exec_qty,
                                _vm.order.asset.info.baseAssetPrecision
                              )
                            ) +
                            " " +
                            _vm._s(_vm.order.LIMIT_MAKER.symbol_from)
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.order.LIMIT_MAKER.status === "FILLED"
                      ? _c("div", [
                          _c("div", { staticClass: "uk-margin" }, [
                            _c(
                              "label",
                              {
                                staticClass: "uk-form-label",
                                attrs: { for: "form-stacked-text" },
                              },
                              [_vm._v("Return")]
                            ),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("float")(
                                    _vm.order.LIMIT_MAKER.price *
                                      _vm.order.LIMIT_MAKER.exec_qty,
                                    _vm.order.asset.info.quoteAssetPrecision
                                  )
                                ) +
                                  " " +
                                  _vm._s(_vm.order.LIMIT_MAKER.symbol_to)
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "uk-margin" }, [
                            _c(
                              "label",
                              {
                                staticClass: "uk-form-label",
                                attrs: { for: "form-stacked-text" },
                              },
                              [_vm._v("Commission paid")]
                            ),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("float")(
                                    _vm.order.LIMIT_MAKER.commission,
                                    _vm.order.asset.info.baseCommissionPrecision
                                  )
                                ) +
                                  " " +
                                  _vm._s(_vm.order.LIMIT_MAKER.commission_asset)
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "uk-margin" }, [
                            _c(
                              "label",
                              {
                                staticClass: "uk-form-label",
                                attrs: { for: "form-stacked-text" },
                              },
                              [_vm._v("Commission quote value")]
                            ),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("float")(
                                    _vm.order.LIMIT_MAKER
                                      .commission_quote_value,
                                    _vm.order.asset.info
                                      .quoteCommissionPrecision
                                  )
                                ) +
                                  " " +
                                  _vm._s(_vm.order.LIMIT_MAKER.symbol_to)
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("div", { staticClass: "uk-margin" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "form-stacked-text" },
                        },
                        [_vm._v("Type")]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(_vm._s(_vm.order.STOP_LOSS_LIMIT.type)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-margin" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "form-stacked-text" },
                        },
                        [_vm._v("Status")]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(_vm._s(_vm.order.STOP_LOSS_LIMIT.status)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-margin" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "form-stacked-text" },
                        },
                        [_vm._v("Create date")]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(_vm._s(_vm.order.STOP_LOSS_LIMIT.created_date)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-margin" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "form-stacked-text" },
                        },
                        [_vm._v("Filled date")]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(_vm._s(_vm.order.STOP_LOSS_LIMIT.updated_date)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-margin" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "form-stacked-text" },
                        },
                        [_vm._v("Price")]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm._f("float")(
                              _vm.order.STOP_LOSS_LIMIT.price,
                              _vm.order.asset.info.quoteAssetPrecision
                            )
                          ) +
                            " " +
                            _vm._s(_vm.order.STOP_LOSS_LIMIT.symbol_to)
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-margin" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "form-stacked-text" },
                        },
                        [_vm._v("Lot (ordered | realized)")]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm._f("float")(
                              _vm.order.STOP_LOSS_LIMIT.orig_qty,
                              _vm.order.asset.info.baseAssetPrecision
                            )
                          ) +
                            " | " +
                            _vm._s(
                              _vm._f("float")(
                                _vm.order.STOP_LOSS_LIMIT.exec_qty,
                                _vm.order.asset.info.baseAssetPrecision
                              )
                            ) +
                            " " +
                            _vm._s(_vm.order.STOP_LOSS_LIMIT.symbol_from)
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.order.STOP_LOSS_LIMIT.status === "FILLED"
                      ? _c("div", [
                          _c("div", { staticClass: "uk-margin" }, [
                            _c(
                              "label",
                              {
                                staticClass: "uk-form-label",
                                attrs: { for: "form-stacked-text" },
                              },
                              [_vm._v("Return")]
                            ),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("float")(
                                    _vm.order.STOP_LOSS_LIMIT.price *
                                      _vm.order.STOP_LOSS_LIMIT.exec_qty,
                                    _vm.order.asset.info.quoteAssetPrecision
                                  )
                                ) +
                                  " " +
                                  _vm._s(_vm.order.STOP_LOSS_LIMIT.symbol_to)
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "uk-margin" }, [
                            _c(
                              "label",
                              {
                                staticClass: "uk-form-label",
                                attrs: { for: "form-stacked-text" },
                              },
                              [_vm._v("Commission paid")]
                            ),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("float")(
                                    _vm.order.STOP_LOSS_LIMIT.commission,
                                    _vm.order.asset.info.baseCommissionPrecision
                                  )
                                ) +
                                  " " +
                                  _vm._s(
                                    _vm.order.STOP_LOSS_LIMIT.commissionAsset
                                  )
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "uk-margin" }, [
                            _c(
                              "label",
                              {
                                staticClass: "uk-form-label",
                                attrs: { for: "form-stacked-text" },
                              },
                              [_vm._v("Commission quote value")]
                            ),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("float")(
                                    _vm.order.STOP_LOSS_LIMIT
                                      .commission_quote_value,
                                    _vm.order.asset.info
                                      .quoteCommissionPrecision
                                  )
                                ) +
                                  " " +
                                  _vm._s(_vm.order.STOP_LOSS_LIMIT.symbol_to)
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "uk-section" }, [
              _c("h2", [_vm._v("Strategy log")]),
              _vm._v(" "),
              _c("p", [_vm._v("Log output of strategy which set the order")]),
              _vm._v(" "),
              _vm.order.log.log !== undefined
                ? _c("textarea", {
                    attrs: { disabled: "" },
                    domProps: { innerHTML: _vm._s(_vm.order.log.log) },
                  })
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }