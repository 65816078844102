<template>
  <div v-if="status === 'idle'">
    <div class="uk-section uk-grid-divider uk-child-width-expand@s" uk-grid>
      <div>
        <form class="uk-form-horizontal uk-margin-large">
          <div class="uk-margin">
            <label class="uk-form-label" for="form-stacked-text">Name</label>
            <div>{{strategy.data.name}}</div>
          </div>
          <div class="uk-margin">
            <label class="uk-form-label" for="form-horizontal-select">Strategy Active</label>
            <div class="uk-form-controls">
              <select v-model="strategy.data.status" class="uk-select">
                <option value="active">On</option>
                <option value="inactive">Off</option>
              </select>
            </div>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label" for="form-horizontal-select">Debug</label>
            <div class="uk-form-controls">
              <VueToggles :value="strategy.options.debug" @click="strategy.options.debug = !strategy.options.debug" height="30"
                width="60"
                checkedText="On"
                uncheckedText="Off"
                checkedBg="#b4d455"
                uncheckedBg="lightgrey" />
              </div>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label" for="form-horizontal-select">Execution mode</label>
            <div class="uk-form-controls">
              <select v-model="strategy.options.mode" class="uk-select">
                <option value="live">Live</option>
                <option value="simulated">Simulated</option>
              </select>
            </div>
          </div>
        </form>
      </div>

      <div>
        <legend class="uk-legend">Parameters</legend>
        <form class="uk-form-horizontal uk-margin-large">
          <div class="uk-margin" v-for="(param, index) in strategy.params" :key="index">
            <label class="uk-form-label" for="form-horizontal-text">{{param.name}}</label>

            <div class="uk-form-controls">
              <input class="uk-input" id="form-horizontal-text" v-model="strategy.params[index].value">
              <span class="uk-text-meta">{{param.description}}</span>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="uk-margin">
      <form class="uk-form-horizontal" uk-grid v-on:submit.prevent="paintChart">
        <div>
          <div class="uk-margin">
            <label class="uk-form-label" for="form-horizontal-select">Filter by Asset</label>
            <div class="uk-form-controls">
              <select class="uk-select" id="form-horizontal-select" v-model="filterPair">
                <option :value="{'symbol_from':'', 'symbol_to':''}">All Pairs</option>
                <option v-for="pair in pairs" :key="pair.id" :value="{'symbol_from': pair.symbol_from, 'symbol_to': pair.symbol_to}">{{pair.symbol_from + pair.symbol_to}}</option>
              </select>
            </div>
          </div>
        </div>
        <div>
          <label class="uk-form-label" for="form-horizontal-select">Success Rate SMA Length</label>
            <div class="uk-form-controls">
              <input class="uk-input" type="number" v-model="srSmaLength">
            </div>
        </div>

        <div>
          <button type="submit" class="uk-button uk-button-primary">Filter</button>
        </div>
      </form>


      <LineChart v-if="status === 'idle'" :data="chartData" :stats="stats" />
    </div>

    <div class="uk-margin">
      <h2>Order Stats per Symbol</h2>
      <table class="uk-table uk-table-small uk-table-divider">
        <thead>
          <tr>
            <th>Symbol</th>
            <th>Orders won</th>
            <th>Orders lost</th>
            <th>Success Rate</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(pair, symbol) in pairs">
            <tr :key="symbol">
              <td>{{symbol}}</td>
              <td>{{pair.true}}</td>
              <td>{{pair.false}}</td>
              <td>{{Math.round((parseInt(pair.true) / (parseInt(pair.true) + parseInt(pair.false))) * 10000, 2) / 100}} %</td>
            </tr>

            <tr v-for="(stats, timeframe) in pair.timeframes" :key="symbol + timeframe">
              <td style="padding-left: 5vw;">{{stats.timeframe}}</td>
              <td>{{stats.true}}</td>
              <td>{{stats.false}}</td>
              <td>{{stats.sr}} %</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
      
    <ul uk-accordion>
      <li>
        <a class="uk-accordion-title" href="#">Latest strategy logs</a>
        <div class="uk-accordion-content">
          <table class="uk-table uk-table-small uk-table-divider uk-table-justify uk-table-middle">
            <thead>
              <tr>
                <th>Date</th>
                <th>Asset</th>
                <th>Mode</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in latestLogs" :key="row.id">
                <td>{{row.date}}</td>
                <td>{{row.symbol_from + row.symbol_to}} {{row.interval}}</td>
                <td>{{row.run}}</td>
                <td>
                  <router-link v-if="row.db_order_id !== null" :to="{name: 'Order', params: {orderId: row.db_order_id}}" v-slot="{ href, navigate}">
                    <a class="uk-button uk-button-default" :href="href" @click="navigate">Go to Order</a>
                  </router-link>
                  
                  <span v-else>
                    Strategy failed
                  </span>
                </td>
                <td><button class="uk-button uk-button-default" type="button" uk-toggle="target: #modal-log" v-on:click="log = row.log">Open Log</button></td>
              </tr>
            </tbody>
          </table>
        </div>
      </li>
    </ul>
    <div id="modal-log" class="uk-modal-container" uk-modal>
      <div class="uk-modal-dialog uk-modal-body" uk-overflow-auto>
        <div v-html="log"></div>
      </div>
    </div>
  </div>
</template>

<script>
    import {mapState} from 'vuex'
    import Axios from 'axios';
    import VueToggles from 'vue-toggles'
    import LineChart from '../components/Chart-Line'

	export default {
		name: 'Strategy',
    components: {
      VueToggles,
      LineChart
    },
		data () {
			return {
        status: 'loading',
        strategy: {},
        latestLogs: [],
        stats: {},
        chartData: {
          datasets: []
        },
        log: '',
        pairs: [],
        filterPair: {
          symbol_from: '',
          symbol_to: ''
        },
        srSmaLength: 20
      }
		},
		mounted: function() {
      this.init();
    },
		methods: {
			onResize(event) {                
        this.width = document.getElementById('viewport').clientWidth
        this.height = window.innerHeight - 15
      },
      init() {
        this.status = 'loading'

        Axios
        .get('strategy?id=' + this.$route.params.strategyId)
        .then( (response) => {
          if (response.data.strategy.options.debug === 'true') {response.data.strategy.options.debug = true} else {response.data.strategy.options.debug = false}

          this.strategy = response.data.strategy
          this.latestLogs = response.data.latestLogs
          this.pairs = response.data.pairs

          this.paintChart()

          this.$nextTick(() => {
            this.status = 'idle'
          })
        })
        .catch((error) => {
          console.log(error)
          
          this.status = 'error'
        })
      },
      paintChart() {
        this.chartData.datasets = []

        Axios
        .get('strategy_stats?id=' + this.$route.params.strategyId + '&symbol_from=' + this.filterPair.symbol_from + '&symbol_to=' + this.filterPair.symbol_to + '&smaLength=' + this.srSmaLength)
        .then( (response) => {
          this.stats = response.data.stats

          // Prepare chart data
          let day
          let successfuls = {
            type: 'bar',
            label: 'Successful Orders',
            data: [],
            yAxisID: 'yAbs',
            xAxisID: 'x',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgb(75, 192, 192)',
            borderWidth: 1,
          }

          let fails = {
            type: 'bar',
            label: 'Failed Orders',
            data: [],
            yAxisID: 'yAbs',
            xAxisID: 'x',
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgb(255, 99, 132)',
            borderWidth: 1,
          }

          let successRate = {
            type: 'line',
            label: 'Success Rate',
            data: [],
            yAxisID: 'yPerc',
            xAxisID: 'x',
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1,
            borderWidth: 1,
            spanGaps: true
          }

          let successRateSMA = {
            type: 'line',
            label: 'Success Rate SMA',
            data: [],
            yAxisID: 'yPerc',
            xAxisID: 'x',
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1,
            borderWidth: 1,
            spanGaps: true
          }

          for(day in this.stats) {
            successfuls.data.push({x: this.stats[day].date, y: this.stats[day].successfuls})
            fails.data.push({x: this.stats[day].date, y: this.stats[day].fails})
            successRate.data.push({x: this.stats[day].date, y: this.stats[day].successRate * 100})
            successRateSMA.data.push({x: this.stats[day].date, y: this.stats[day].successRateSMA * 100})
          }
          
          this.chartData.datasets.push(successfuls)
          this.chartData.datasets.push(fails)
          this.chartData.datasets.push(successRate)
          this.chartData.datasets.push(successRateSMA)
        })
        .catch((error) => {
          console.log(error)
          
          this.status = 'error'
        })
      }
    },
		computed: {},
    watch: {
      '$route.params.orderId': function (id) {
        this.init()
      },
      'strategy': {
        deep: true,
        handler: function(after, before) {
          if(this.status === 'idle') {
            this.$store.dispatch('saveStrategy', this.strategy)
            .then(() => {

            })
          }
        }
      }
    }
	}
	
</script>

<style scoped lang="scss">

  @import 'Order';
</style>
