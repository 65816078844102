import 'babel-polyfill';
// -------------------------------------------------------------------- libs ---
import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'
import Vue from 'vue'
import VueRouter from 'vue-router'

import App from './App.vue';

require('./styles/theme.scss');

// -------------------------------------------------------------- Vuex store ---
import store from './store';

// ------------------------------------------------------------- Vue plugins ---
import VueToggles from 'vue-toggles';

Vue.use(VueToggles);

UIkit.use(Icons);

Vue.use(VueRouter);

// --------------------------------------------------------------- Data Filters ---
function pad(n) {
    return n<10 ? '0'+n : n;
}

Vue.filter('date', function (value) {
  let currentDate = new Date(value)
  return currentDate
})

Vue.filter('timestamp', function (value) {
  let currentDate = new Date(value)
  return currentDate
})

Vue.filter('float', function (value, decimals = 2) {

  return parseFloat(parseFloat(value).toFixed(decimals))
})

Vue.filter('currency', function (value) {
  var currency = {
    factor: 1,
    symbol: '€',
    decimals: 2
  }

  value = value * currency.factor
  return currency.symbol + ' ' + value.toFixed(currency.decimals).replace(/(\d)(?=(\d{3})+(,|$))/g, '$1,')
})

Vue.filter('durationString', function (value) {

  let seconds = Number(value)

  var h = Math.floor(seconds / 3600);
  var m = Math.floor(seconds % 3600 / 60);
  var s = Math.floor(seconds % 3600 % 60);

  var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
  return hDisplay + mDisplay + sDisplay;
})


// --------------------------------------------------------------- Custom global Components ---
Vue.component('heading', {
  props: ['title'],
  data: function () {
    return {}
  },
  template: '<div class="uk-text-lead section-heading"><span><slot></slot></span></div>'
})

// --------------------------------------------------------------- Vue setup ---
// global config for XHR requests
import Axios from 'axios';
const qs = require('qs');
Axios.defaults.baseURL = 'api/'
Axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';

// Axios.interceptors.request.use(config => {
//   const params = new URLSearchParams();
//   Object.keys(config.data).forEach(key => {
//     params.append(key, config.data[key]);
//   });
//   config.data = params
//   return config;
// });

Axios.interceptors.request.use(config => {
  config.data = qs.stringify(config.data)
  return config;
});


import routes from './routes'
import { preventExtensions } from 'core-js/core/object';

const router = new VueRouter({
	routes,
	linkActiveClass: 'uk-active',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

// The App itself
const ocoSelfService = new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app');

// --------------------------------------------------------------- Vue mount ---

// // Need to wait for window to load
// window.onload = () => ocoSelfService.$mount('.app');
