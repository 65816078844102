<template>
	<div>
        <h1>Orders</h1>
        <table class="uk-table uk-table-divider">
            <thead>
                <tr>
                    <th>Order Date</th>
                    <th>Strategy</th>
                    <th>Symbol</th>
                    <th>Success</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="order in orders" :key="order.id">
                    <td>{{order.created_date}}</td>
                    <td><router-link :to="{ name: 'Strategy', params: {strategyId: order.strategy_id }}">{{order.strategy_name}}</router-link></td>
                    <td>{{order.symbol}}</td>
                    <td>{{order.success}}</td>
                    <td>
                        <router-link :to="{ path: 'order/' + order.id }">Open order</router-link>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import Axios from 'axios';

	export default {
		name: 'Orders',
        components: {
        },
		data () {
			return {
                orders: []
			}
		},
		mounted: function() {
            Axios
        	.get('/orders')
        	.then( (response) => {	
        		this.orders = response.data
        	})
        	.catch(function (error) {
        		console.log(error)
                
        	})
        },
		methods: {
			getCardImage: function(cardType) {
                
            },
		},
		computed: {
			
		},
	}
	
</script>

<style scoped lang="scss">
  @import 'Orders';
</style>
