// Manipulate from the current state.
export default {
	SET_ORDER (state, order) {
		state.order = order
	},
	SET_ASSETS (state, data) {
		state.assets = data.assets
		state.assetsGrouped = data.assetsGrouped
	},
	SET_STRATEGIES (state, data) {
		state.strategies = data
	},
	SET_APP_STATUS (state, status) {
		state.status = status
	}
};
