import { Overlay } from 'trading-vue-js'

export default {
    name: 'TradeSimple',
    mixins: [Overlay],
    data() { },
    methods: {
        draw(ctx) {
            if (this.$props.data.length === 0) {
                return false
            }
            
            let layout = this.$props.layout // Layout object (see API BOOK)
            ctx.lineWidth = 1.5
            ctx.strokeStyle = 'white'
            ctx.font = '14px Arial'
            ctx.textAlign = 'center'

            const signalbar = this.$props.data[0][7]

            let entryDate = layout.t2screen(this.$props.data[0][0])
            let exitDate = layout.t2screen(this.$props.data[0][1])
            let buyLvl = layout.$2screen(this.$props.data[0][2])
            let targetProfitLvl = layout.$2screen(this.$props.data[0][3])
            let stopLossLvl = layout.$2screen(this.$props.data[0][4])

            // Draw entry lvl
            ctx.beginPath()
            ctx.strokeStyle = 'white'
            ctx.moveTo(entryDate, buyLvl);
            ctx.lineTo(exitDate, buyLvl);
            ctx.stroke();

            // Set label
            ctx.fillStyle = 'rgb(255,225,255)';
            ctx.textAlign = 'left'
            ctx.fillText('Entry: ' + this.$props.data[0][2] + signalbar.asset.data.symbol_to, exitDate + 5, buyLvl + 4)

            // ctx.fillStyle = 'yellow'
            // ctx.strokeStyle = 'yellow'
            // ctx.beginPath()
            // ctx.arc(entryDate, buyLvl, 5.5, 0, Math.PI * 2, true)
            // ctx.fill()
            // ctx.stroke()
            
            // Draw target line
            ctx.beginPath()
            ctx.strokeStyle = 'green'
            ctx.moveTo(entryDate, targetProfitLvl);
            ctx.lineTo(exitDate, targetProfitLvl);
            ctx.stroke();

            // Set label
            ctx.fillStyle = 'green';
            ctx.textAlign = 'left'
            ctx.fillText('TP: ' + this.$props.data[0][3] + ' ' + signalbar.asset.data.symbol_to, exitDate + 5, targetProfitLvl + 4)

            // Draw stoploss line
            ctx.beginPath()
            ctx.strokeStyle = 'red'
            ctx.moveTo(entryDate, stopLossLvl);
            ctx.lineTo(exitDate, stopLossLvl);
            ctx.stroke();

            // Set label
            ctx.fillStyle = 'red';
            ctx.textAlign = 'left'
            ctx.fillText('SL: ' + this.$props.data[0][4] + ' ' + signalbar.asset.data.symbol_to, exitDate + 5, stopLossLvl + 4)

            const barLength = signalbar.barLengthPips
            
            // Set label
            ctx.fillStyle = 'white';
            ctx.textAlign = 'center'
            ctx.fillText('BarLength: ' + barLength + ' Pips', layout.t2screen(signalbar.data.unix_open * 1000), layout.$2screen(signalbar.data.high) - 5)

        },
        use_for() { return ['TradeSimple'] },
        legend(values) {
            return [
                {
                    value: `Entry: ${values[2]}`,
                    color: 'white'
                },
                {
                    value: `TP: ${values[3]}`,
                    color: 'green'
                },
                {
                    value: `SL: ${values[4]}`,
                    color: 'red'
                }
            ]
        }
    }
}