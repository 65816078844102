var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _c(
      "div",
      {
        attrs: {
          "uk-sticky":
            "sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky;",
        },
      },
      [
        _c(
          "nav",
          {
            staticClass: "navbar uk-navbar-container uk-background-default",
            attrs: { "uk-navbar": "" },
          },
          [
            _vm._m(0),
            _vm._v(" "),
            this.$route.name === "Order" &&
            this.$store.state.status !== "loading"
              ? _c("div", { staticClass: "uk-navbar-right" }, [
                  _c("div", { staticClass: "uk-navbar-item" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.orderFilter,
                            expression: "orderFilter",
                          },
                        ],
                        staticClass: "uk-select uk-display-inline",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.orderFilter = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "none" } }, [
                          _vm._v("Filter by symbol"),
                        ]),
                        _vm._v(" "),
                        _vm._l(
                          _vm.$store.state.assetsGrouped,
                          function (asset) {
                            return _c("option", { key: asset.id }, [
                              _vm._v(
                                _vm._s(
                                  asset.symbol_from + "/" + asset.symbol_to
                                )
                              ),
                            ])
                          }
                        ),
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "uk-navbar-item" }, [
                    _c(
                      "span",
                      {
                        staticClass: "uk-margin-remove-bottom uk-margin-right",
                      },
                      [_vm._v("Order #" + _vm._s(_vm.$store.state.order.id))]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "uk-navbar-item" },
                    [
                      this.$store.state.order.previousId !== null
                        ? _c("router-link", {
                            attrs: {
                              to: {
                                name: "Order",
                                params: {
                                  orderId: this.$store.state.order.previousId,
                                },
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var href = ref.href
                                    var navigate = ref.navigate
                                    return [
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "uk-button uk-button-default uk-margin-right",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\tPrevious order\n\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              509790791
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      this.$store.state.order.nextId !== null
                        ? _c("router-link", {
                            attrs: {
                              to: {
                                name: "Order",
                                params: {
                                  orderId: this.$store.state.order.nextId,
                                },
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var href = ref.href
                                    var navigate = ref.navigate
                                    return [
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "uk-button uk-button-default uk-margin-right",
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\tNext order\n\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2123023985
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c("div", { attrs: { id: "sidebar", "uk-offcanvas": "overlay: true" } }, [
      _c("div", { staticClass: "uk-offcanvas-bar" }, [
        _c(
          "ul",
          { staticClass: "uk-nav uk-nav-default" },
          [
            _c("router-link", {
              attrs: { to: { name: "Dashboard" } },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var href = ref.href
                    var navigate = ref.navigate
                    var isActive = ref.isActive
                    return [
                      _c("li", { class: { "uk-active": isActive } }, [
                        _c(
                          "a",
                          { attrs: { href: href }, on: { click: navigate } },
                          [_vm._v("Dashboard")]
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("router-link", {
              attrs: { to: { name: "Strategies" } },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var href = ref.href
                    var navigate = ref.navigate
                    var isActive = ref.isActive
                    return [
                      _c("li", { class: { "uk-active": isActive } }, [
                        _c(
                          "a",
                          { attrs: { href: href }, on: { click: navigate } },
                          [_vm._v("Strategies")]
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("router-link", {
              attrs: { to: { name: "Orders" } },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var href = ref.href
                    var navigate = ref.navigate
                    var isActive = ref.isActive
                    return [
                      _c("li", { class: { "uk-active": isActive } }, [
                        _c(
                          "a",
                          { attrs: { href: href }, on: { click: navigate } },
                          [_vm._v("Orders")]
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("router-link", {
              attrs: { to: { name: "OrdersSimulated" } },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var href = ref.href
                    var navigate = ref.navigate
                    var isActive = ref.isActive
                    return [
                      _c("li", { class: { "uk-active": isActive } }, [
                        _c(
                          "a",
                          { attrs: { href: href }, on: { click: navigate } },
                          [_vm._v("Simulated Orders")]
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("main", { staticClass: "uk-width-expand uk-padding-small" }, [
      _vm.$store.state.status === "idle"
        ? _c("div", { attrs: { id: "viewport" } }, [_c("router-view")], 1)
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "uk-navbar-left" }, [
      _c(
        "button",
        {
          staticClass: "uk-button uk-button-link",
          attrs: { type: "button", "uk-toggle": "target: #sidebar" },
        },
        [_c("span", { attrs: { "uk-icon": "icon: menu; ratio: 1.5" } })]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }