var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.status === "idle"
    ? _c("div", [
        _c(
          "div",
          {
            staticClass: "uk-section uk-grid-divider uk-child-width-expand@s",
            attrs: { "uk-grid": "" },
          },
          [
            _c("div", [
              _c(
                "form",
                { staticClass: "uk-form-horizontal uk-margin-large" },
                [
                  _c("div", { staticClass: "uk-margin" }, [
                    _c(
                      "label",
                      {
                        staticClass: "uk-form-label",
                        attrs: { for: "form-stacked-text" },
                      },
                      [_vm._v("Name")]
                    ),
                    _vm._v(" "),
                    _c("div", [_vm._v(_vm._s(_vm.strategy.data.name))]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "uk-margin" }, [
                    _c(
                      "label",
                      {
                        staticClass: "uk-form-label",
                        attrs: { for: "form-horizontal-select" },
                      },
                      [_vm._v("Strategy Active")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-form-controls" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.strategy.data.status,
                              expression: "strategy.data.status",
                            },
                          ],
                          staticClass: "uk-select",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.strategy.data,
                                "status",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "active" } }, [
                            _vm._v("On"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "inactive" } }, [
                            _vm._v("Off"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "uk-margin" }, [
                    _c(
                      "label",
                      {
                        staticClass: "uk-form-label",
                        attrs: { for: "form-horizontal-select" },
                      },
                      [_vm._v("Debug")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "uk-form-controls" },
                      [
                        _c("VueToggles", {
                          attrs: {
                            value: _vm.strategy.options.debug,
                            height: "30",
                            width: "60",
                            checkedText: "On",
                            uncheckedText: "Off",
                            checkedBg: "#b4d455",
                            uncheckedBg: "lightgrey",
                          },
                          on: {
                            click: function ($event) {
                              _vm.strategy.options.debug =
                                !_vm.strategy.options.debug
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "uk-margin" }, [
                    _c(
                      "label",
                      {
                        staticClass: "uk-form-label",
                        attrs: { for: "form-horizontal-select" },
                      },
                      [_vm._v("Execution mode")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-form-controls" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.strategy.options.mode,
                              expression: "strategy.options.mode",
                            },
                          ],
                          staticClass: "uk-select",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.strategy.options,
                                "mode",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "live" } }, [
                            _vm._v("Live"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "simulated" } }, [
                            _vm._v("Simulated"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("legend", { staticClass: "uk-legend" }, [
                _vm._v("Parameters"),
              ]),
              _vm._v(" "),
              _c(
                "form",
                { staticClass: "uk-form-horizontal uk-margin-large" },
                _vm._l(_vm.strategy.params, function (param, index) {
                  return _c("div", { key: index, staticClass: "uk-margin" }, [
                    _c(
                      "label",
                      {
                        staticClass: "uk-form-label",
                        attrs: { for: "form-horizontal-text" },
                      },
                      [_vm._v(_vm._s(param.name))]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-form-controls" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.strategy.params[index].value,
                            expression: "strategy.params[index].value",
                          },
                        ],
                        staticClass: "uk-input",
                        attrs: { id: "form-horizontal-text" },
                        domProps: { value: _vm.strategy.params[index].value },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.strategy.params[index],
                              "value",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "uk-text-meta" }, [
                        _vm._v(_vm._s(param.description)),
                      ]),
                    ]),
                  ])
                }),
                0
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "uk-margin" },
          [
            _c(
              "form",
              {
                staticClass: "uk-form-horizontal",
                attrs: { "uk-grid": "" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.paintChart.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", [
                  _c("div", { staticClass: "uk-margin" }, [
                    _c(
                      "label",
                      {
                        staticClass: "uk-form-label",
                        attrs: { for: "form-horizontal-select" },
                      },
                      [_vm._v("Filter by Asset")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-form-controls" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.filterPair,
                              expression: "filterPair",
                            },
                          ],
                          staticClass: "uk-select",
                          attrs: { id: "form-horizontal-select" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.filterPair = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        [
                          _c(
                            "option",
                            {
                              domProps: {
                                value: { symbol_from: "", symbol_to: "" },
                              },
                            },
                            [_vm._v("All Pairs")]
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.pairs, function (pair) {
                            return _c(
                              "option",
                              {
                                key: pair.id,
                                domProps: {
                                  value: {
                                    symbol_from: pair.symbol_from,
                                    symbol_to: pair.symbol_to,
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(pair.symbol_from + pair.symbol_to)
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "label",
                    {
                      staticClass: "uk-form-label",
                      attrs: { for: "form-horizontal-select" },
                    },
                    [_vm._v("Success Rate SMA Length")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "uk-form-controls" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.srSmaLength,
                          expression: "srSmaLength",
                        },
                      ],
                      staticClass: "uk-input",
                      attrs: { type: "number" },
                      domProps: { value: _vm.srSmaLength },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.srSmaLength = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _vm._m(0),
              ]
            ),
            _vm._v(" "),
            _vm.status === "idle"
              ? _c("LineChart", {
                  attrs: { data: _vm.chartData, stats: _vm.stats },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-margin" }, [
          _c("h2", [_vm._v("Order Stats per Symbol")]),
          _vm._v(" "),
          _c(
            "table",
            { staticClass: "uk-table uk-table-small uk-table-divider" },
            [
              _vm._m(1),
              _vm._v(" "),
              _c(
                "tbody",
                [
                  _vm._l(_vm.pairs, function (pair, symbol) {
                    return [
                      _c("tr", { key: symbol }, [
                        _c("td", [_vm._v(_vm._s(symbol))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(pair.true))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(pair.false))]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              Math.round(
                                (parseInt(pair.true) /
                                  (parseInt(pair.true) +
                                    parseInt(pair.false))) *
                                  10000,
                                2
                              ) / 100
                            ) + " %"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm._l(pair.timeframes, function (stats, timeframe) {
                        return _c("tr", { key: symbol + timeframe }, [
                          _c("td", { staticStyle: { "padding-left": "5vw" } }, [
                            _vm._v(_vm._s(stats.timeframe)),
                          ]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(stats.true))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(stats.false))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(stats.sr) + " %")]),
                        ])
                      }),
                    ]
                  }),
                ],
                2
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("ul", { attrs: { "uk-accordion": "" } }, [
          _c("li", [
            _c(
              "a",
              { staticClass: "uk-accordion-title", attrs: { href: "#" } },
              [_vm._v("Latest strategy logs")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "uk-accordion-content" }, [
              _c(
                "table",
                {
                  staticClass:
                    "uk-table uk-table-small uk-table-divider uk-table-justify uk-table-middle",
                },
                [
                  _vm._m(2),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.latestLogs, function (row) {
                      return _c("tr", { key: row.id }, [
                        _c("td", [_vm._v(_vm._s(row.date))]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(row.symbol_from + row.symbol_to) +
                              " " +
                              _vm._s(row.interval)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(row.run))]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            row.db_order_id !== null
                              ? _c("router-link", {
                                  attrs: {
                                    to: {
                                      name: "Order",
                                      params: { orderId: row.db_order_id },
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var href = ref.href
                                          var navigate = ref.navigate
                                          return [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "uk-button uk-button-default",
                                                attrs: { href: href },
                                                on: { click: navigate },
                                              },
                                              [_vm._v("Go to Order")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              : _c("span", [
                                  _vm._v(
                                    "\n                  Strategy failed\n                "
                                  ),
                                ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "button",
                            {
                              staticClass: "uk-button uk-button-default",
                              attrs: {
                                type: "button",
                                "uk-toggle": "target: #modal-log",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.log = row.log
                                },
                              },
                            },
                            [_vm._v("Open Log")]
                          ),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "uk-modal-container",
            attrs: { id: "modal-log", "uk-modal": "" },
          },
          [
            _c(
              "div",
              {
                staticClass: "uk-modal-dialog uk-modal-body",
                attrs: { "uk-overflow-auto": "" },
              },
              [_c("div", { domProps: { innerHTML: _vm._s(_vm.log) } })]
            ),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "button",
        {
          staticClass: "uk-button uk-button-primary",
          attrs: { type: "submit" },
        },
        [_vm._v("Filter")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Symbol")]),
        _vm._v(" "),
        _c("th", [_vm._v("Orders won")]),
        _vm._v(" "),
        _c("th", [_vm._v("Orders lost")]),
        _vm._v(" "),
        _c("th", [_vm._v("Success Rate")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Date")]),
        _vm._v(" "),
        _c("th", [_vm._v("Asset")]),
        _vm._v(" "),
        _c("th", [_vm._v("Mode")]),
        _vm._v(" "),
        _c("th", [_vm._v(" ")]),
        _vm._v(" "),
        _c("th", [_vm._v(" ")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }