import Vue from 'vue';
import Vuex from 'vuex';
import Axios from 'axios';
import State from './store/state'
import Actions from './store/actions'
import Mutations from './store/mutations'
Vue.use(Vuex);


// Not quite sure why I need this but without "const" it throws an error ...
const state = State
const actions = Actions
const mutations = Mutations
const getters = {};

export default new Vuex.Store({
	state,
	getters,
	mutations,
	actions
});
