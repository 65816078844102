import { Overlay } from 'trading-vue-js'

export default {
    name: 'Trade',
    mixins: [Overlay],
    methods: {
        draw(ctx) {            
            let layout = this.$props.layout // Layout object (see API BOOK)
            ctx.lineWidth = 1.5
            ctx.strokeStyle = 'white'
            ctx.font = '16px Arial'
            ctx.textAlign = 'center'

            ctx.beginPath()

            for (var i in this.$props.data) {              
                
                let entryDate = layout.t2screen(this.$props.data[0][0])
                let exitDate = layout.t2screen(this.$props.data[0][1])
                let buyLvl = layout.$2screen(this.$props.data[0][2])
                let targetProfitLvl = layout.$2screen(this.$props.data[0][3])
                let stopLossLvl = layout.$2screen(this.$props.data[0][4])
                let width = exitDate - entryDate
                let height = targetProfitLvl - buyLvl
                let profit = 1 - (this.$props.data[0][2] / this.$props.data[0][3])
                let loss = 1 - (this.$props.data[0][4] / this.$props.data[0][2])

                // Draw profit range
                ctx.fillStyle = 'rgba(0,225,0,0.2)';
                ctx.fillRect(entryDate, buyLvl, width, height);

                // Draw stop loss range
                width = exitDate - entryDate
                height = stopLossLvl - buyLvl
                ctx.fillStyle = 'rgba(225,0,0,0.2)';
                ctx.fillRect(entryDate, buyLvl, width, height);
                
                // Set profit label
                ctx.fillStyle = 'rgba(0,225,0,0.4)';
                ctx.fillText('+' + (profit * 100).toFixed(2) + '%', entryDate + ((exitDate - entryDate) / 2), targetProfitLvl + 25)

                // Set Loss label
                ctx.fillStyle = 'rgba(225,0,0,0.4)';
                ctx.fillText('-' + (loss * 100).toFixed(2) + '%', entryDate + ((exitDate - entryDate) / 2), stopLossLvl - 25)
            }

            ctx.stroke()
        },
        use_for() { return ['Trades'] }
    }
}