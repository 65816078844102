<template>
  <div>
    <canvas id="myChart"></canvas>
  </div>
</template>

<script>
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
} from 'chart.js';

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
);

export default {
  name: 'LineChart',
  components: {},
  props: {
    data: {
      type: Object,
      default: {}
    },
    stats: {
      type: Object,
      default: {}
    },
  },
  data() {
    return {
      myChart: {}
    }
  },
  mounted: function () {

    this.myChart = new Chart(
      document.getElementById('myChart'),
      {
        type: 'line',
        data: this.data,
        options: {
          responsive: true,
          plugins: {
            tooltip : {
              mode : 'index',
              position: 'nearest',
              callbacks: {
                afterLabel: function(context) {
                  if (context.datasetIndex === 2) {
                    return '%'
                  } else {
                    return ''
                  }
                }
              }
            }
          },
          onClick: (evt) => {
            const points = myChart.getElementsAtEventForMode(evt, 'nearest', { intersect: true }, true);

            if (points.length) {
                const firstPoint = points[0];
                // see https://www.chartjs.org/docs/latest/developers/api.html#getelementsateventformode-e-mode-options-usefinalposition
                console.log(this.stats[Object.keys(this.stats)[firstPoint.element.$context.dataIndex]].order_ids)

            }
          },
          scales: {
            x: {
              stacked: true,
              ticks: {
                stepSize: 1
              }
            },
            yPerc: {
              position: 'right', // `axis` is determined by the position as `'y'`
              type: 'linear',
              title: {
                display: true,
                text: 'Success Rate %'
              }
            },
            yAbs: {
              type: 'linear',
              stacked: true,
              title: {
                display: true,
                text: 'Number of orders'
              }
            }
          }
        }
      }
    )
  },
  watch: {
    stats: function (newStats, oldStats) {
      this.myChart.update('active')
    }
  }
}
</script>