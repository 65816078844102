<template>
    <div>
        <TradingVue ref="tvjs" :data="chart" :chart-config=" { DEFAULT_LEN: 100 }" :width="this.width" :height="this.height" :overlays="overlays" :toolbar="true" :title-txt="chartTitle"></TradingVue>

        <div v-if="status === 'idle'">
            <div class="uk-section">
                <div class="uk-grid-divider uk-child-width-expand@s" uk-grid>
                    <div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Symbol</label>
                            <div>{{order.symbol_from + order.symbol_to}}</div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Timeframe</label>
                            <div>{{order.asset.timeframe}}</div>
                        </div>

                        <div class="uk-margin" uk-grid>
                            <div>
                                <label class="uk-form-label" for="form-stacked-text">Entry date</label>
                                <div>{{order.created_date}}</div>
                            </div>
                            
                            <div>
                                <label class="uk-form-label" for="form-stacked-text">Exit date</label>
                                <div>{{order.updated_date}}</div>
                            </div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Duration</label>
                            <div>{{durationComp}}</div>
                        </div>
                    </div>

                    <div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Strategy</label>
                            <div>
                                <router-link :to="{name: 'Strategy', params: {strategyId: order.strategy.data.id}}" v-slot="{ href, navigate}">
                                    <a :href="href" @click="navigate">{{order.strategy.data.name}}</a>
                                </router-link>
                            </div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Profit | Loss</label>
                            <div :class="{'uk-text-success': profitComp[0] > 0, 'uk-text-danger': profitComp[0] < 0}">
                                <span v-if="profitComp[0] > 0">+</span><span v-else>-</span>{{profitComp[0] | float(order.asset.info.quoteAssetPrecision)}} {{order.symbol_to}} ({{profitComp[1]}} %)
                            </div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Risk Reward Ratio (wanted | realized)</label>
                            <div>{{order.strategy.options.riskRewardRatio | float(2)}} | {{RRRComp | float}}</div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Invest</label>
                            <div>{{(order.exec_qty * order.entry_price) | float(order.asset.info.quoteAssetPrecision)}} {{order.symbol_to}}</div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Lot</label>
                            <div>{{order.exec_qty | float(order.asset.info.baseAssetPrecision)}} {{order.symbol_from}}</div>
                        </div>
                    </div>

                    <div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Total commission paid</label>
                            <div>{{totalCommissionComp | float(order.asset.info.baseCommissionPrecision)}} {{order.commission_asset}}</div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Total commission quote value</label>
                            <div>{{totalCommissionQuoteComp | float(order.asset.info.quoteCommissionPrecision) }} {{order.symbol_to}}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="uk-section">
                <div class="uk-grid-divider uk-child-width-expand@s" uk-grid>
                    <div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Type</label>
                            <div>{{order.type}}</div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Status</label>
                            <div>{{order.status}}</div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Create date</label>
                            <div>{{order.created_date}}</div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Filled date</label>
                            <div>{{order.filled_date}}</div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Price</label>
                            <div>{{order.price | float(order.asset.info.quoteAssetPrecision)}} {{order.symbol_to}}</div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Lot</label>
                            <div>{{order.exec_qty | float(order.asset.info.baseAssetPrecision)}} {{order.symbol_from}}</div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Invest</label>
                            <div>{{order.entry_price * order.exec_qty | float(order.asset.info.quoteAssetPrecision)}} {{order.symbol_to}}</div>
                        </div>

                        <div v-if="order.status === 'FILLED'" class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Commission paid</label>
                            <div>{{order.commission | float(order.asset.info.baseCommissionPrecision)}} {{order.commission_asset}}</div>
                        </div>

                        <div v-if="order.status === 'FILLED'" class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Commission quote value</label>
                            <div>{{order.commission_quote_value | float(order.asset.info.quoteCommissionPrecision)}} {{order.symbol_to}}</div>
                        </div>
                    </div>

                    <div>
                        <!-- <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Type</label>
                            <div>{{order.LIMIT_MAKER.type}}</div>
                        </div> -->

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Status</label>
                            <div>{{order.status}}</div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Create date</label>
                            <div>{{order.created_date}}</div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Filled date</label>
                            <div>{{order.fill_date}}</div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Price</label>
                            <div>{{order.entry_price | float(order.asset.info.quoteAssetPrecision)}} {{order.asset.data.symbol_to}}</div>
                        </div>

                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Lot (ordered | realized)</label>
                            <div>{{order.exec_qty | float(order.asset.info.baseAssetPrecision)}} | {{order.exec_qty | float(order.asset.info.baseAssetPrecision)}} {{order.asset.data.symbol_from}}</div>
                        </div>

                        <div v-if="order.success === 'true'">
                            <div class="uk-margin">
                                <label class="uk-form-label" for="form-stacked-text">Return</label>
                                <div>{{order.entry_price * order.exec_qty | float(order.asset.info.quoteAssetPrecision)}} {{order.asset.data.symbol_to}}</div>
                            </div>

                            <!-- <div class="uk-margin">
                                <label class="uk-form-label" for="form-stacked-text">Commission paid</label>
                                <div>{{order.LIMIT_MAKER.commission | float(order.asset.info.baseCommissionPrecision)}} {{order.LIMIT_MAKER.commission_asset}}</div>
                            </div> -->

                            <!-- <div class="uk-margin">
                                <label class="uk-form-label" for="form-stacked-text">Commission quote value</label>
                                <div>{{order.LIMIT_MAKER.commission_quote_value | float(order.asset.info.quoteCommissionPrecision)}} {{order.LIMIT_MAKER.symbol_to}}</div>
                            </div> -->
                        </div>
                    </div>

                    
                </div>
            </div>

            <div class="uk-section">
                <h2>Strategy log</h2>
                <p>Log output of strategy which set the order</p>
                <textarea v-if="order.log.log !== undefined" v-html="order.log.log" disabled></textarea>
            </div>
        </div>
    </div>
</template>

<script>
    import { TradingVue, DataCube } from 'trading-vue-js'
    import Trade from './Trade.js'
    import TradeSimple from './TradeSimple.js'
    import DrawLine from './Line.js'
    import Overlays from 'tvjs-overlays'
    import Axios from 'axios'

	export default {
		name: 'OrderSimulated',
        components: { TradingVue },
		data () {
			return {
                status: 'loading',
                chartTitle: '',
                chart: new DataCube({
                    grid: { "logScale": true },
                    ohlcv: [],
                    chart: { tf: '15m', data: []},
                    onchart: [
                        { type: 'BB', name: 'BBL', data: [], settings: { lineWidth: false, color: false, backColor: false, showMid: true }}
                    ],
                    offchart:  [
                        {
                            name: "MACD", type: "MACD", data: [],
                            settings: { fast: 12, slow: 26, smooth: 9, macdColor: 'blue', signalColor: 'orange', histColors: [ "#35a776", "#79e0b3", "#e54150", "#ea969e"]}
                        },
                        {
                            type: 'RSI', name: 'RSI(4)', data: [],
                            settings: {
                                length: 4
                            }
                        },
                        {
                            type: 'RSI', name: 'RSI(14)', data: [],
                            settings: {
                                length: 14
                            }
                        },
                        {
                            type: 'ATR', name: 'ATR', data: [],
                            settings: {
                                length: 14
                            }
                        }
                    ]
                }),
                width: window.innerWidth,
                height: window.innerHeight,
                overlays: [Trade, TradeSimple, Overlays['MACD'], Overlays['BB'], Overlays['EMA'], Overlays['RSI'], Overlays['ATR'], DrawLine]
            }
		},
		mounted: function() {
            this.init();
        },
		methods: {
			onResize(event) {                
                this.width = document.getElementById('viewport').clientWidth
                this.height = window.innerHeight - 15
            },
            init() {
                this.status = 'loading'
                Axios
        		.get('ordersimulated?id=' + this.$route.params.orderId)
        		.then( (response) => {
                    this.order = response.data
                    
                    window.addEventListener('resize', this.onResize)
                    this.chartTitle = this.order.asset.data.symbol_from + this.order.asset.data.symbol_to + ' ' + this.order.asset.data.timeframe

                    // Set up original timeframe chart
                    var ema52 = [],
                        ema104 = [],
                        ema208 = [],
                        vci = [],
                        dailyHigh = [],
                        dailyLow = [];

                    var ohlcv = []
                    this.order.ohlc.forEach((bar) => {
                        var row = new Array( parseInt(bar.unix_open * 1000), parseFloat(bar.open), parseFloat(bar.high), parseFloat(bar.low), parseFloat(bar.close), parseFloat(bar.asset_volume))
                        
                        ohlcv.push(row)
                    })

                    this.chart.set('chart.data', ohlcv)
                    this.chart.set('chart.tf', this.order.asset.data.timeframe)

                    this.chart.add('onchart', {
                        name: "Trade",
                        type: "TradeSimple",
                        data: [
                            [
                                parseInt(this.order.created_unix * 1000),               // timestamp on order create
                                parseInt(this.order.sold_unix * 1000),   // timestamp on order exit
                                parseFloat(this.order.entry_price),                    // buy lvl 
                                parseFloat(this.order.exit_price),        // target profit lvl
                                parseFloat(this.order.stop_price),    // stop loss lvl
                                this.order.created_date,
                                this.order.updated_date,
                                this.order.signalbar
                            ]
                        ],
                        settings: {
                            "z-index": 1
                        }
                    })

                    const prevDailyBar = this.order.ohlcDaily

                    this.chart.add('onchart', {
                        name: "PDHL",
                        type: "DrawLine",
                        data: [
                            [
                                (parseInt(this.order.created_unix) - (parseInt(this.order.asset.timeframeS) * 10)) * 1000,     // start X
                                parseFloat(prevDailyBar.data.low),                               // start Y
                                (parseInt(this.order.sold_unix) + (parseInt(this.order.asset.timeframeS) * 10)) * 1000,     // End X
                                parseFloat(prevDailyBar.data.low),                                // end Y
                                'Prev Daily Low'
                            ],
                            [
                                (parseInt(this.order.created_unix) - (parseInt(this.order.asset.timeframeS) * 10)) * 1000,     // start X
                                parseFloat(prevDailyBar.data.high),                               // start Y
                                (parseInt(this.order.sold_unix) + (parseInt(this.order.asset.timeframeS) * 10)) * 1000,     // End X
                                parseFloat(prevDailyBar.data.high),                                // end Y
                                'Prev Daily High'
                            ]
                        ],
                        "settings": {
                            "z-index": 1
                        }
                    })

                    // this.chart.add('onchart', { "name": "EMA52", "type": "Spline", "data": ema52, "settings": { color: 'green' }})
                    // this.chart.add('onchart', { "name": "EMA104", "type": "Spline", "data": ema104, "settings": { color: 'orange' }})
                    // this.chart.add('onchart', { "name": "EMA208", "type": "Spline", "data": ema208, "settings": { color: 'red' }})

                    // this.chart.add('offchart', { "name": "VCI", "type": "Candles", "data": vci, settings: {priceLine: false, showVolume: false, colorCandleUp: 'yellow'}})

                    this.status = 'idle';
                    
                    this.onResize()
                    window.dc = this.chart
                    window.tv = this.$refs.tvjs
                })
                .catch((error) => {
        			console.log(error)
        		})
            }
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize)
		},
		computed: {
            profitComp() {
                let invest = this.order.entry_price * this.order.exec_qty
                let roi = 0.0
                let profit = 0.0
                let loss = 0.0
                if (this.order.success === 'true') {
                    roi = this.order.exit_price * this.order.exec_qty
                    profit = 100 * (1 - (this.order.entry_price / this.order.exit_price))

                } else if (this.order.success === 'false') {
                    roi = this.order.stop_price * this.order.exec_qty
                    profit = 100 * (1 - (this.order.entry_price / this.order.stop_price))
                }

                return [(roi - invest).toFixed(2), profit.toFixed(2)]
            },
            durationComp() {
                let seconds = Number(this.order.updated_unix - this.order.created_unix)
    
                var h = Math.floor(seconds / 3600);
                var m = Math.floor(seconds % 3600 / 60);
                var s = Math.floor(seconds % 3600 % 60);

                var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
                var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
                var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
                return hDisplay + mDisplay + sDisplay;

            },
            totalCommissionComp() {
                // if (this.order.success === 'true')
                //     return parseFloat(this.order.commission) + parseFloat(this.order.LIMIT_MAKER.commission)                    
                // else
                //     return parseFloat(this.order.commission) + parseFloat(this.order.STOP_LOSS_LIMIT.commission)
                return 0.0
            },
            totalCommissionQuoteComp() {
                // if (this.order.success)
                //     return parseFloat(this.order.commission_quote_value) + parseFloat(this.order.LIMIT_MAKER.commission_quote_value)                    
                // else
                //     return parseFloat(this.order.commission_quote_value) + parseFloat(this.order.STOP_LOSS_LIMIT.commission_quote_value)
                return 0.0
            },
            RRRComp() {
                return (this.order.exit_price - this.order.entry_price) / (this.order.entry_price - this.order.stop_price)
            }
        },
        watch: {
            '$route.params.orderId': function (id) {                
                this.$refs.tvjs.resetChart()
                this.chart.del('Trades')
                this.status = 'loading'
                this.init()
            }
        },
	}
	
</script>

<style scoped lang="scss">

  @import 'Order';
</style>
