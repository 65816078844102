<template>
	<div class="wrapper">
		<div uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky;">
			<nav class="navbar uk-navbar-container uk-background-default" uk-navbar>
				<div class="uk-navbar-left">
					<button class="uk-button uk-button-link" type="button" uk-toggle="target: #sidebar"><span uk-icon="icon: menu; ratio: 1.5"></span></button>
				</div>

				<div class="uk-navbar-right" v-if="this.$route.name === 'Order' && this.$store.state.status !== 'loading'">
					<div class="uk-navbar-item">
						<select class="uk-select uk-display-inline" v-model="orderFilter">
							<option value="none">Filter by symbol</option>
							<option v-for="asset in $store.state.assetsGrouped" :key="asset.id">{{asset.symbol_from + '/' + asset.symbol_to}}</option>
						</select>
					</div>
					
					<div class="uk-navbar-item">
						<span class="uk-margin-remove-bottom uk-margin-right">Order #{{$store.state.order.id}}</span>
					</div>

					<div class="uk-navbar-item">
						<router-link v-if="this.$store.state.order.previousId !== null" :to="{name: 'Order', params: {'orderId': this.$store.state.order.previousId}}" v-slot="{ href, navigate }">
							<a class="uk-button uk-button-default uk-margin-right" :href="href" @click="navigate">
								Previous order
							</a>
						</router-link>

						<router-link v-if="this.$store.state.order.nextId !== null" :to="{name: 'Order', params: {orderId: this.$store.state.order.nextId}}" v-slot="{ href, navigate}">
							<a class="uk-button uk-button-default uk-margin-right" :href="href" @click="navigate">
								Next order
							</a>
						</router-link>
					</div>
				</div>
			</nav>
		</div>

		<div id="sidebar" uk-offcanvas="overlay: true">
			<div class="uk-offcanvas-bar">

				<ul class="uk-nav uk-nav-default">
					<router-link :to="{name: 'Dashboard'}" v-slot="{ href, navigate, isActive }">
						<li :class="{'uk-active': isActive}">
							<a :href="href" @click="navigate">Dashboard</a>
						</li>
					</router-link>

					<router-link :to="{name: 'Strategies'}" v-slot="{ href, navigate, isActive }">
						<li :class="{'uk-active': isActive}">
							<a :href="href" @click="navigate">Strategies</a>
						</li>
					</router-link>
					
					<router-link :to="{name: 'Orders'}" v-slot="{ href, navigate, isActive }">
						<li :class="{'uk-active': isActive}">
							<a :href="href" @click="navigate">Orders</a>
						</li>
					</router-link>

					<router-link :to="{name: 'OrdersSimulated'}" v-slot="{ href, navigate, isActive }">
						<li :class="{'uk-active': isActive}">
							<a :href="href" @click="navigate">Simulated Orders</a>
						</li>
					</router-link>
				</ul>

			</div>
		</div>

		<main class="uk-width-expand uk-padding-small">
			<div id="viewport" v-if="$store.state.status === 'idle'">
				<router-view></router-view>
			</div>
		</main>
	</div>
</template>

<script>
	import {mapState} from 'vuex'
	export default {
		components: {
		},
		data () {
			return {}
		},
		computed: {
			...mapState({
                orderFilter: state => state.orderFilter
            }),
		},
		mounted: function() {
			this.initializeApp()
		},
		methods: {
			initializeApp: function() {				
				this.$store.dispatch('initializeApp', {
					showLoad: true
				})
			}
		},
		watch: {
            '$route.path': function (id) {                
                UIkit.offcanvas('#sidebar').hide();
            }
        }
	}
</script>

<style scoped>

</style>