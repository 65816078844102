export default {
	status: 'loading',
	orderFilter: 'none',
	order: {
		previousId: null,
		nextId: null,
		asset: {},
		LIMIT_MAKER: {},
		STOP_LOSS_LIMIT: {},
		log: {
			log: ''
		}
	},
	strategies: [],
}
